// OBJECT.BODY-TEXT

// First class here targets the RTE within the CMS so it will also get styled
.mce-content-body {
	padding: 10px;
}

.mce-content-body,
.o-body-text {
	@include clearfix;

	& + & {
		margin-top: $bsu;
	}

	> *:first-child {
		margin-top: 0;
	}

	> *:last-child {
		margin-bottom: 0;
	}

	h2,
	h3,
	h4,
	h5 {
		margin: 1.2em 0 0.6em;
	}

	h2 {
		@include font-32h;
	}

	h3 {
		@include font-24b;
	}

	h4 {
		@include font-20b;
	}

	h5 {
		@include font-18b;
	}

	> p {
		@include font-16;
		margin-bottom: 1.5em;

		@include bp('large') {
			@include font-18;
		}
	}

	a {
		@include link-brand;
	}

	hr {
		border-width: 1px 0 0;
		border-top: 1px solid $base-ui-color;
		height: 0;
		clear: both;
		margin: 1.5em 0;
	}

	> ul,
	> ol {
		padding: 0 0 0 1em;
		margin: 1.5em 0;

		li {
			@include font-16;
			margin-bottom: 1em;

			@include bp('large') {
				@include font-18;
			}
		}

		ul,
		ol {
			margin: 0.25em 0;
		}

		ul {

			li {
				list-style-type: circle;
			}
		}

		ol {

			li {
				list-style-type: lower-roman;
			}
		}
	}

	> ul > li {
		list-style-type: disc;
	}

	> ol > li {
		list-style-type: decimal;
	}

	table {
		width: 100%;
		min-width: 480px;
		clear: both;
		margin-bottom: $bsu;
		border-collapse: collapse;

		p {
			margin: 0;
		}
		// Remove default min-width if content editor has set the width in the CMS
		&[style*="width"] {
			min-width: 0;
		}
		// Add margin to right if content editor has set the table to float left in the CMS
		&[style*="float: left"] {
			margin-right: $bsu;
		}
		// Add margin to left if content editor has set the table to float right in the CMS
		&[style*="float: right"] {
			margin-left: $bsu;
		}
	}

	.tablescroll table {
		margin-bottom: 0;
	}

	th {
		@include font-16b;
		background-color: $grey--lightest;

		@include bp('large') {
			@include font-18b;
		}
	}

	thead {

		td {
			@include font-16b;
			background-color: $grey--lightest;

			@include bp('large') {
				@include font-18b;
			}
		}
	}

	th,
	td {
		@include ui-border('all');
		padding: $ssu $bsu;
	}

	td {
		@include font-16;
		vertical-align: top;

		@include bp('large') {
			@include font-18;
		}
	}

	caption {
		@include ui-border(bottom);
		@include font-12;
		caption-side: bottom;
		padding: $bsu 0;
		text-align: left;
	}
}

// RTE figure - shared styling across macros
.o-body-text__figure {
	margin: $lsu auto;
	clear: both;

	&.u-float--left {
		clear: left;

		@include bp('small') {
			margin: 0 $lsu $bsu 0;
		}
	}

	&.u-float--right {
		clear: right;

		@include bp('small') {
			margin: 0 0 $bsu $lsu;
		}
	}
}

.o-body-text__figcaption {
	@include ui-border('bottom');
	@include font-12;
	padding: $bsu 0
}
