﻿// CONTROL.CTA-VERBOSE

.c-cta-verbose {}

.c-cta-verbose__bg {
	border: none;
}

.c-cta-verbose__body {
	padding: $bsu;
}

.c-cta-verbose__body-wrapper {
	> *:last-child {
		margin-bottom: 0;
	}
}

.c-cta-verbose__heading {}

.c-cta-verbose__text {
	margin-bottom: 2em;
	@include font-14;
}

.c-cta-verbose__link {}

.o-layout-homepage,
.o-layout-main {

	.c-cta-verbose__body {
		background-color: $white;
		border: 1px solid $brand;
		color: $brand;
		padding: $bsu;

		@include bp("medium") {
			@include flex-extend;
			flex-wrap: nowrap;
			align-items: center;
		}
	}

	.c-cta-verbose__body-wrapper {
		flex-grow: 1;
		margin-bottom: $bsu;

		@include bp('medium') {
			padding-right: $lsu;
			margin-bottom: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	.c-cta-verbose__heading {
		@include font-20h;
		color: $brand;
	}

	.c-cta-verbose__text {
	}

	.c-cta-verbose__link {
		@extend .o-btn;
		@extend .o-btn--small;
		white-space: nowrap;
	}

	.c-cta-verbose__body--dark {
		background-color: $brand;

		.c-cta-verbose__heading,
		.c-cta-verbose__text {
			color: $white;
		}

		.c-cta-verbose__link {
			background: $white;
			box-shadow: inset 0 0 0 1px $brand;
			color: $brand;

			&:hover,
			&:focus {
				background: $brand;
				box-shadow: inset 0 0 0 1px $white;
				color: $white;
			}
		}
	}
}

.o-layout-aside {

	.c-cta-verbose__body {
		background: $grey--darkest;
		border: none;
		color: $white;

		padding-top: $lsu;
		margin-bottom: $lsu;

		position: relative;
		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;

			border-top: 15px solid $white;
			border-left: #{15px * $brand-angle-multiplier} solid $white;

			border-bottom: 15px solid transparent;
			border-right: #{15px * $brand-angle-multiplier} solid transparent;
		}
	}

	.c-cta-verbose__body-wrapper {
		margin-bottom: 0;
	}

	.c-cta-verbose__heading {
		color: $white;
		@include font-20;
	}

	.c-cta-verbose__text {
		color: $white;
	}

	.c-cta-verbose__link {
		@extend .o-btn;
		@extend .o-btn--small;
		white-space: nowrap;

		margin-bottom: -$bsu;
		transform: translateY($bsu);
	}
}