// COMPONENT.HEADER-GERARD

.c-header {}

.c-header__spacer {
	margin-bottom: 0;
}

.c-header__top {
	background: $white;
}

.c-header__wrapper {
	@include bp-below($header-bp) {
		height: 65px;
	}
}

.c-header__logo {
	padding: 0;
	@include bp($header-bp) {
		&::after {
			display: none;
		}
	}
}

.c-header__logo-gerard {
	position: relative;
	display: block;
	height: 66px;
	width: auto;
	top: 0;
	left: $bsu;

	@include bp($header-bp) {
		@include bp-below("extra-large") {
			left: $tsu;
			// Align to bottom of header when at the top of the page
			top: 30px;
			.c-header.is-sticky & {
				top: 0;
			}
			@include link-transition(top);
		}
	}

	@include bp("extra-large") {
		top: 0;
		left: $lsu;
		height: 96px;

		.c-header.is-sticky & {
			height: 66px;
		}
	}

	@include link-transition(height);
}

.c-header__logo-metrotile {
	position: relative;
	@include bp($header-bp) {
		top: 28px;
		left: 5px;
	}

	display: block;
	height: 30px;
	width: 115px;

	@include link-transition(top);
}

@include bp($header-bp) {
	.c-header.is-sticky {
		.c-header__logo-metrotile {
			top: 8px;
		}
	}
}

.c-header__top-link,
.c-header__regions-trigger {
	font-weight: $weight--bold;
}

.c-header__top-link--brand {
	background: $brand--alt;
	&:hover,
	&:focus {
		background: $brand--light;
	}
}
