// COMPONENT.HEADER

$header-top-height: 30px;
$header-height--mobile: 65px;
$header-height--desktop: 65px;

$header-bp: "large";

.c-header__spacer {
	height: $header-height--mobile;

	@include bp($header-bp) {
		height: $header-top-height + $header-height--desktop;
		margin-bottom: $lsu;
	}
}

.c-header {
	position: fixed;
	z-index: $z-header;
	top: 0;
	height: $header-height--mobile;

	@include bp($header-bp) {
		height: $header-height--desktop + $header-top-height;
	}

	width: 100%;
	background-color: $white;
	@include link-transition;

	&.is-sticky {
		height: $header-height--mobile;

		.c-header__top {
			margin-top: -$header-top-height;
		}

		box-shadow: 0 8px 6px -6px rgba($black, 0.2);
	}

	&.is-up {
		transform: translateY(calc(-100% - 6px));
	}
}

.c-header__top {
	height: $header-top-height;
	background: $grey--light;

	transition: all 0.25s ease-in-out;

	display: none;
	@include bp($header-bp) {
		display: block;
	}
}

.c-header__top-wrapper {
	@include flex-extend;
	align-items: stretch;
	justify-content: flex-end;
}

.c-header__top-link {
	height: $header-top-height;
	@include font-14;
	line-height: $header-top-height;

	color: $grey--darkest;

	padding: 0 20px;
	@include link-transition;

	&:hover,
	&:focus {
		background: $white;
	}
}

.c-header__top-link--brand {
	background: $brand;
	color: $white;

	&:hover,
	&:focus {
		background: $brand--alt;
	}
}

.c-header__top-links-mobile {
	@include flex-extend;
	flex-wrap: wrap;
	padding: 20px;

	@include bp($header-bp) {
		display: none;
	}

	.c-header__top-links-item {
		flex: 1 1 50%;
		&:nth-child(2n) {
			padding-left: 5px;
		}
		&:nth-child(2n+1) {
			padding-right: 5px;
		}
		&:nth-child(n+3) {
			padding-top: 10px;
		}

		@include flex-extend;
		align-items: stretch;

		.c-header__top-link {
			flex-grow: 1;

			box-shadow: 0 0 0 1px $brand;
			background: $white;
			color: $brand;
			&:hover,
			&:focus {
				background: $brand;
				color: $white;
			}

			@include font-16b;
			height: 60px;

			@include flex-extend;
			align-items: center;
			justify-content: center;
		}
		.c-header__top-link--brand {
			background: $brand;
			color: $white;
			&:hover,
			&:focus {
				background: $white;
				color: $brand;
			}
		}
	}
}

.c-header__language {
	@include flex-extend;
	align-items: stretch;
	height: $header-top-height;

	background: $grey--dark;
	color: $white;

	padding: 0 20px;

	&::before {
		@include font-icon;
		content: $iconf-globe;

		font-size: 18px;
		line-height: $header-top-height;

		display: inline-block;
		margin-right: 10px;
	}
}

.c-header__language-dropdown {
	appearance: none;
	cursor: pointer;

	background: $grey--dark;
	color: $white;
	border: none;

	@include font-14;
	height: $header-top-height;
	line-height: $header-top-height;
	padding: 0 20px;
}


.c-header__wrapper {
	@include flex-extend;
	align-items: center;
	justify-content: space-between;
	padding: 10px 8px 10px 20px;
	@include bp($header-bp) {
		justify-content: flex-end;
		padding: 0 20px;
	}

}

.c-header__logo {
	@include bp($header-bp) {
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		padding: $ssu;
		text-align: center;

		@include flex-extend;

		&::after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;

			pointer-events: none;

			border-top: 75px solid $brand;
			border-left: #{75px * $brand-angle-multiplier} solid $brand;

			border-bottom: 75px solid transparent;
			border-right: #{75px * $brand-angle-multiplier} solid transparent;

			@include bp("extra-large") {
				border-top-width: 100px;
				border-left-width: #{100px * $brand-angle-multiplier};

				border-bottom-width: 100px;
				border-right-width: #{100px * $brand-angle-multiplier};
			}

			transition: all 0.25s ease-in-out;
		}
	}
}

.c-header__logo-mobile {
	@include bp($header-bp) {
		display: none;
	}
	height: 30px;
}

.c-header__logo-primary,
.c-header__logo-secondary {
	display: none;
	@include bp($header-bp) {
		display: block;
	}
	height: 25px;
	@include bp("extra-large") {
		height: 30px;
	}

	position: relative;
	top: 10px;
	@include bp("extra-large") {
		top: 20px;
	}
	z-index: 1;

	@include link-transition;
}
.c-header__logo-primary {
	width: 25px; // IE11 is a piece of shit that doesn't understand SVGs have aspect ratios
	@include bp("extra-large") {
		width: 30px;
	}
}
.c-header__logo-secondary {
	margin-left: 10px;
	width: 97px; // IE11 is a piece of shit that doesn't understand SVGs have aspect ratios
	@include bp("extra-large") {
		width: 117px;
	}
}

.c-header.is-sticky {
	.c-header__logo::after {
		border-top: 45px solid $brand;
		border-left: #{45px * $brand-angle-multiplier} solid $brand;

		border-bottom: 45px solid transparent;
		border-right: #{45px * $brand-angle-multiplier} solid transparent;
	}

	.c-header__logo-primary,
	.c-header__logo-secondary {
		top: 0;
	}

	.c-header__logo-secondary {
		opacity: 0;
	}
}

.c-header__tools {
	@include flex-extend;
	flex: 0 0 auto;
	align-items: center;
	justify-content: flex-end;
	order: 3;

	@include bp($header-bp) {
		padding-right: $bsu;
	}
}

.c-header__quote {
	order: 4;

	display: none;
	@include bp($header-bp) {
		display: block;
	}
}


// Mobile buttons
$mobile-button-size: 45px;
$mobile-button-color: $brand;
$mobile-button--hover-color: darken($brand, 15);
$mobile-button-font-size: 20px;
$mobile-button-padding: ($mobile-button-size - $mobile-button-font-size) / 2;

.c-header__mobile-buttons {
	flex: 1 0 auto;
	text-align: right;

	@include bp("medium") {
		flex: 0 0 auto;
	}
}

.c-header__mobile-button {
	position: relative;
	width: $mobile-button-size;
	height: $mobile-button-size;
	border: 0;
	background: none;
	transition: all 0.3s ease-in-out;
	cursor: pointer;
}

// Nav Toggle
.c-header__nav-toggle {
	@include button-reset;

	@include bp("large") {
		display: none;
	}

	b {
		transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out, border-color 0.3s ease-in-out;
		position: absolute;
		left: $mobile-button-padding;
		display: block;
		width: $mobile-button-font-size;
		height: 0;
		border-top: 2px solid $mobile-button-color;

		&:nth-child(1) { top: $mobile-button-padding; }
		&:nth-child(2) { top: $mobile-button-padding + $mobile-button-font-size / 2; }
		&:nth-child(3) { top: $mobile-button-padding + $mobile-button-font-size; }
	}

	&[aria-expanded="true"] {

		b {
			&:nth-child(1) { transform: translateY(#{$mobile-button-font-size / 2}) rotate(45deg); }
			&:nth-child(2) { opacity: 0; }
			&:nth-child(3) { transform: translateY(-#{$mobile-button-font-size / 2}) rotate(-45deg); }
		}
	}

	&:hover,
	&[aria-expanded="true"] {
		b {
			border-color: $mobile-button--hover-color;
		}
	}
}

// Search Toggle
.c-header__search-toggle {
	font-size: $mobile-button-font-size;
	color: $mobile-button-color;
	cursor: pointer;

	text-align: center;

	&:hover,
	&[aria-expanded="true"] {
		color: $mobile-button--hover-color;
	}

	&[aria-expanded="true"] .iconf-search {
		&::before {
			content: $iconf-close;
			font-size: $mobile-button-font-size - 3px;
		}
	}

	.iconf-search {
		position: absolute;
		left: $mobile-button-padding;
		top: $mobile-button-padding;
	}
}

.c-header__search {
	display: none;
	position: absolute;
	background: $white;
	top: $header-height--mobile;
	left: 0;
	width: 100%;
	height: calc(100vh - #{$header-height--mobile});
	box-shadow: 0 8px 6px -6px rgba(0,0,0,0.2);

	@include bp("large") {
		position: absolute;
		left: 0;
		top: $header-height--desktop + $header-top-height;
		height: auto;
		@include link-transition(top);
	}
}
@include bp("large") {
	.c-header.is-sticky {
		.c-header__search {
			top: $header-height--desktop;
		}
	}
}

.c-header__search-wrapper {
	width: 100%;
	position: absolute;
	top: 0;
	right: 50%;
	transform: translate(50%, 0);

	background: $grey--lightest;

	@include bp($header-bp) {
		width: 700px;
		position: static;
		padding: 50px 0 80px;
		margin: 0 auto;
		top: auto;
		left: auto;
		transform: none;
		background: none;
	}
}

.c-header__search-field {
	position: relative;
}

.c-header__search-input {
	@include placeholder-text($grey);
	display: block;
	padding: 15px 59px 15px 15px;
	@include font-18;
	@include bp($header-bp) {
		@include font-16;
		padding: 0.3em 44px 0.3em 0.5em;
		border-bottom: 1px solid $grey;
	}
	width: 100%;
	margin: 0 auto;
	border: none;
	border-radius: 0;
	background: transparent;
}

.c-header__search-btn {
	position: absolute;
	padding: 0;
	width: 44px;
	height: 44px;
	border: none;
	background: 0 0;
	right: $ssu;
	top: 50%;
	transform: translateY(-50%);
	@include bp($header-bp) {
		top: -4px;
		right: 0;
		transform: none;
	}
	font-size: 20px;
	line-height: 1;
	color: $brand;
	cursor: pointer;

	&:before {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	&:hover {
		color: darken($brand, 10%);
	}
}

/////////////
// REGIONS //
/////////////
.c-header__regions {
	@include flex-extend;
	align-items: center;

	height: $header-top-height;
	@include font-14;
	line-height: $header-top-height;

	background: $grey--dark;
	color: $white;

	&.c-header__regions--has-region {
		padding: 0 20px;
	}
}

.c-header__regions-current {}

.c-header__regions-current--mobile {
	@include flex-extend;
	align-items: center;
	@include bp($header-bp) {
		display: none;
	}

	width: 100%;
	text-align: left;

	padding: $ssu $bsu;
	background: $grey--lightest;
}

.c-header__regions-current-flag {
	display: inline-block;
	vertical-align: middle;

	margin-right: $ssu;
	width: 20px;
}

.c-header__regions-current-name {
	display: inline-block;
	vertical-align: middle;

	margin-right: $ssu;
}

.c-header__regions-trigger {
	@include button-reset;

	width: 100%;
	text-align: left;

	background: $grey--lightest;
	&:hover,
	&:focus {
		background: $grey--light;
	}
	@include bp($header-bp) {
		width: auto;
		text-align: center;

		background: $grey--dark;
		color: $white;
		&:hover,
		&:focus {
			background: $grey--darkest;
		}
	}

	height: $header-top-height;
	@include font-14;
	line-height: $header-top-height;

	cursor: pointer;

	padding: 0 20px;
	@include link-transition;

	&::before {
		@include font-icon;
		content: $iconf-globe;

		display: inline-block;
		vertical-align: middle;
		margin-right: $ssu;
	}
}
.c-header__regions-trigger--mobile {
	@include bp($header-bp) {
		display: none;
	}
}

.c-header__regions-trigger--small {
	display: inline-block;
	vertical-align: bottom;

	@include button-reset;

	cursor: pointer;

	@include font-12;
	@include bp($header-bp) {
		color: $white;
	}
	text-decoration: underline;
}

.c-header__regions-title {
	display: block;
	margin-bottom: $bsu;

	text-align: center;
	@include font-24h;
}
.c-header__regions-introduction {
	display: block;
	margin-bottom: $bsu;

	text-align: center;
	@include font-16;
}

.c-header__regions-global-button {
	display: block;
	margin-top: $bsu;
}

.c-header__regions-list {
	@include list-reset;
}

.c-header__regions-item {
	padding-bottom: $bsu;
	border-bottom: 1px solid $grey;
	margin-bottom: $bsu;

	&:last-child {
		padding-bottom: 0;
		border-bottom: none;
		margin-bottom: 0;
	}
}

.c-header__regions-heading {
	display: block;
	margin-bottom: $ssu;

	@include font-16h;

	&:last-child {
		margin-bottom: 0;
	}
}
a.c-header__regions-heading {
	@include link-brand;
}

.c-header__regions-country-list {
	@include list-reset;
	@include flex-extend;
	align-items: space-between;
	flex-wrap: wrap;

	margin: -$ssu 0 0 (-$ssu); // Brackets to stop SCSS thinking it's (0 -$ssu)
}

.c-header__regions-country-item {
	min-width: 120px;
	width: 25%;
	padding-top: $ssu;
	padding-left: $ssu;
}

.c-header__regions-country-link {
	@include link-brand;
	@include flex-extend;
	align-items: center;

	padding: 2px 5px;
	&:hover {
		background: $grey--light;
	}
}

.c-header__regions-country-flag {
	display: block;
	width: 48px;
	margin-right: $ssu;

}

.c-header__regions-country-name {
	padding: 3px 0;
	@include font-14;
}
