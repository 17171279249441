﻿// CONTROL.IMAGE-CAROUSEL

.c-image-carousel {
	@include clearfix;

	.flickity-prev-next-button {
		svg {
			display: none;
		}

		&::before {
			@include font-icon;
			@include link-brand;
			@include link-transition;

			font-size: 24px;
		}

		&.previous {
			left: 0;
			&::before {
				content: $iconf-arrow-left;
			}
		}

		&.next {
			right: 0;
			&::before {
				content: $iconf-arrow-right;
			}
		}
	}
}

.c-image-carousel__items {
	@include list-reset;
	.slick-arrow:before {
		color: $body-color;
	}
}

.c-image-carousel__item {
	display: inline-block;
	width: 100%;
	padding: $ssu/2;

	&:nth-child(n+2) {
		display: none;

		.flickity-enabled & {
			display: block;
		}
	}

	@include bp('medium') {
		width: 33%;
		float: left;

		&:nth-child(n+2) {
			display: inline-block;
		}

		&:nth-child(n+4) {
			display: none;
		}
	}

	@include bp('large') {
		width: 16.6667%;
		float: left;

		&:nth-child(n+4) {
			display: inline-block;
		}

		&:nth-child(n+7) {
			display: none;
		}
	}
}

.c-image-carousel__slide-link {
	/*@include ui-border('all');
	padding: $lsu;*/
}

.c-image-carousel__items {
	padding: 0 $lsu !important;
	max-height: auto;

	@include bp('large') {
		max-height: 120px;
	}
}