.js-modal-trigger {
	&:hover {
		cursor: pointer;
	}
}

.c-modal {
	display: none;

	&[aria-hidden="false"] {
		display: block;
	}

	position: fixed;
	z-index: 10;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba($black, 0.6);
}

.c-modal__body-open {
	// To be placed on the <body> tag when a modal is open
	// Uncomment this rule to prevent background scrolling
	// while a modal window is open:
	overflow: hidden;
}


.c-modal__body {
	position: absolute;
	@include abs-center;
	transform: translate(50%, -50%) scaleZ(1);
	overflow: auto;
	width: 100%;
	height: 100%;
	padding: 44px 20px 20px;
	background: $white;
	box-shadow: 0 0 20px rgba($black, 0.4);

	@include bp("medium") {
		width: 800px;
		max-width: 90%;
		height: auto;
		max-height: 80%;
		padding: $lsu;
	}
}

.c-modal__height-set {
	height: 100%
}

.c-modal__image {
	display: block;
	overflow: hidden;
	margin: 0 auto;
	max-height: 300px;
	max-width: 300px;

	@include bp('small') {
		max-height: 400px;
		max-width: 400px;
	}

	@include bp('large') {
		max-height: 90%;
		max-width: 700px;
	}
}


.c-modal__image-caption {
	margin-top: 15px;
	text-align: center;
	display: block;
	@include font-18;
}

.c-modal--contrast {

	.c-modal__body {
		background: $grey--lighter;
	}
}

.c-modal--full {
	.c-modal__body {
		width: 100%;
		height: 100%;
		max-width: 100%;
		max-height: 100%;
		box-shadow: none;
	}

	.c-modal__close {
		position: fixed;
		z-index: 1;
		top: 0;
		right: 0;

		@include bp("medium") {
			top: $bsu;
			right: $bsu;
		}
	}

	background: $white;
}


// INSPIRATION MODAL
.c-modal--inspo {
	.c-modal-rotator__title,
	.c-modal-rotator__location,
	.c-modal-rotator__info,
	.c-share {
		display: none;

		@include bp("medium") {
			display: flex;
		}
	}

	.c-image-zoom {
		font-size: 20px;
		background: rgba($white, 0.7);
		color: $red;
		border: 0;

		.c-image-zoom__text {
			@include font-14h;
		}

		@include bp("medium") {
			display: none;
		}
	}

	.c-image-zoom--in {
		position: absolute;
		top: 0;
		left: 0;
	}

	.c-image-zoom--out {
		display: none;
	}

	.o-fit.c-modal-rotator__rotator-slide-image {
		@include bp("large") {
			padding-bottom: 40%;
		}
	}

	.c-modal-rotator__title {
		padding-right: 60px;
	}

	.c-modal-rotator {
		padding: 0;
		margin-bottom: $lsu;

		@include bp("medium") {
			padding: 0 $ssu $ssu;
		}
	}

	.c-modal-rotator__info {
		margin-bottom: $ssu;
	}

	.c-modal-rotator__rotator {
		width: calc(100% + #{$lsu});
		margin-left: -$bsu;

		@include bp("medium") {
			width: 100%;
			margin-left: 0;
		}
	}

	.c-modal-rotator__rotator-slides {
		margin-top: 0;
	}

	.c-modal-rotator__rotator-slide-caption {
		text-align: center;
		margin-top: $ssu;
	}

	.c-share {
		margin-top: 0;
		margin-bottom: 0;
	}

	.flickity-page-dots {
		display: none !important;
	}

	.flickity-button {
		background: rgba($white, 0.7);
		display: flex;
		align-items: center;
		justify-content: center;

		&.previous {
			right: auto !important;
			left: 0 !important;
		}

		&.next {
			left: auto !important;
			right: 0 !important;
		}
	}

	.c-modal__body {
		@include bp("medium") {
			padding: 20px;
		}
	}
}

.has-active-zoom {
	overflow: hidden;

	.c-image-zoom--in,
	.flickity-button,
	.flickity-page-dots,
	.c-modal-rotator__info,
	.c-modal-rotator__title,
	.c-modal-rotator__location,
	.c-share,
	.c-modal__close {
		display: none;
	}

	.c-image-zoom--out {
		display: inline-block;
		position: fixed;
		z-index: 99;
	}

	&.c-modal--inspo .c-modal-rotator__rotator {
		width: 100%;
		margin-left: 0;
	}

	.c-modal__body {
		padding: 0;
	}

	.c-modal-rotator {
		margin-bottom: 0;
	}

	.flickity-viewport {
		height: 100vh !important;
	}
}

.c-modal__close {
	position: absolute;
	top: 0;
	right: 0;

	@include button-reset;
	display: block;
	padding: 10px;
	font-size: 24px;
	line-height: 1;
	color: $black;

	&:hover,
	&:focus {
		color: $brand;
	}

	&:before {
		@include font-icon;
		content: $iconf-close;
	}
}

.c-modal .o-layout-wrapper .c-modal__close {
	margin-top: -44px;

	@include bp("medium") {
		margin-top: -$lsu;
	}
}

.c-modal__dummy {
	position: absolute;
	clip: rect(0 0 0 0);
	overflow: hidden;
	width: 1px;
	height: 1px;
	padding: 0;
	border: 0;
	margin: -1px;
}
/////////////
// ROTATOR //
/////////////
.c-modal-rotator {
	padding: 0 0px;
	height: 100%;
	margin-bottom: 0px !important;

	@include bp("medium") {
		padding-top: $bsu;
		height: auto;
	}

	@include bp(1440px) {
		padding-left: 0;
		padding-right: 0;
	}

	.c-modal-rotator__rotator-wrapper {
		height: 100%;

		.c-modal-rotator__rotator {
			height: 100%;
		}
	}
}

.c-modal-rotator__title {
	display: block;

	@include font-16h;
	margin-bottom: $ssu;

	@include bp("medium") {
		@include font-32h;
		margin-bottom: $bsu;
	}
}

.c-modal-rotator__location {
	display: block;
	margin-bottom: $ssu;
	@include font-16b;

	@include bp("large") {
		margin-bottom: $bsu;
	}
}

.c-modal-rotator__info {
	@include flex-extend;
	align-items: center;
	margin-bottom: $bsu;

	@include bp-below("medium") {
		flex-wrap: wrap;
	}
}

.c-modal-rotator__info-image-container {
	flex-shrink: 0;
	width: 60px;
	margin-right: $bsu;
}

.c-modal-rotator__info-image {
	display: block;
	width: 100%;
}

.c-modal-rotator__info-detail-list {
	@include flex-extend;
	margin-left: -$bsu;
	margin-right: $bsu;

	@include bp-below("medium") {
		flex-direction: column;
	}
}

.c-modal-rotator__info-detail-item {
	@include flex-extend;
	padding-left: $bsu;
}

.c-modal-rotator__info-detail-term {
	@include font-14;
}

.c-modal-rotator__info-detail-description {
	@include font-14;
	margin-left: $tsu;
}

.c-modal-rotator__info-name {
	display: block;
	margin-right: $bsu;

	@include font-20h;
	color: $brand;
}

.c-modal-rotator__info-button {
	@include bp-below("medium") {
		margin-top: $ssu;
	}

	margin-right: $ssu;
}

.c-modal-rotator__rotator {
}

.c-modal-rotator__rotator-slides {
	margin-top: 10px;

	@include bp("medium") {
		margin-top: 30px;
	}

	background: linear-gradient(from top, 0 $grey--lightest, 50% $grey--lightest, 50% transparent, 100% transparent);

	@include bp-below("medium") {
		.flickity-prev-next-button {
			.flickity-button-icon {
				@include abs-center;
				width: 50%;
				height: 50%;
			}
		}
	}
}

.c-modal-rotator__rotator-slide {
	display: block;
	width: 100%;
	top: 50%;
	transform: translateY(-50%);
}

.c-modal-rotator__rotator-image-container {
	@include flex-extend;
	justify-content: center;
	align-items: center;
	margin-bottom: 10px;
	height: 100%;
}

.c-modal-rotator__rotator-slide-image {
	display: block;
	max-width: 100%;

	&.o-fit {
		// Extra class for specificity
		background-color: transparent;
		height: 100%;

		@include bp("medium") {
			padding-bottom: 50%;
		}
	}
}

.c-modal-rotator__rotator-slide-captions {
	display: block;
	text-align: center;
}

@media print {
	.c-modal__body-open {
		overflow: auto;

		.c-header,
		.c-header__spacer,
		.c-hero,
		#main > :not(.c-modal),
		.c-share,
		.c-breadcrumb,
		.c-footer {
			display: none;
		}

		.c-modal[aria-hidden="false"] {
			position: relative;

			.c-modal__body {
				position: relative;
			}
		}

		.c-modal--full {
			.c-modal__body {
				transform: translateX(50%);
				top: 0;
				width: 100% !important;
				height: auto !important;
				overflow: visible;
			}
		}

		.c-modal__close {
			display: none;
		}
	}
}

// Swiper

.swiper {

	&.c-hero {
		&:hover {
			cursor: grab;
		}

		.swiper-buttons {
			font-size: 80px;
			color: #fff;
		}
	}

	&.c-modal-rotator__rotator {
		width: calc(100% + 40px);
		margin-left: -20px;

		.swiper-wrapper {
			align-items: center;
		}

		.swiper-buttons {
			font-size: 25px;
			color: $brand;
			top: calc(100% - 55px);
			background: #fff;

			@include bp("medium") {
				top: 50%;
			}
		}
	}

	&.c-image-gallery__slides {
		.swiper-buttons {
			font-size: 25px;
			color: #fff;
		}
	}

	&.c-image-carousel__items {
		.swiper-buttons {
			font-size: 25px;
			color: $brand;
			top: 0;
			bottom: 0;
			height: 100%;
			margin-top: 0;
			background: white;
			width: 40px;

			&.swiper-button-prev {
				left: 0;
			}

			&.swiper-button-next {
				right: 0;
			}
		}
	}

	.swiper-buttons {
		&:after {
			display: none;
		}
	}
}

.c-modal.has-active-zoom {
	.swiper-buttons {
		display: none;
	}

	.swiper-slide {
		display: none;

		&.js-can-zoom {
			display: none;
		}

		&.swiper-slide-active {

			&.js-can-zoom {
				overflow: auto !important;
				top: 0 !important;
				transform: translate(0, 0) !important;
				//position: fixed !important;
				height: 100vh !important;
				bottom: 0;
				z-index: 999;
				display: block;
				/*				touch-action: pinch-zoom;*/

				.o-fit__image {
					left: auto !important;
					top: auto !important;
					width: 250% !important;
					height: auto;
				}
			}
		}
	}
}

.swiper-slide {
	.swiper-zoom-container {
		display: block;
		text-align: left;
	}
}

.c-modal-rotator__rotator-slide-caption {
	position: absolute;
	bottom: -15px;
	left: 0;
	right: 0;
	padding: 5px 10px;
	background: #fff;
	height: 45px;
	text-align: center;

	@include bp("medium") {
		position: relative;
		bottom: auto;
		left: auto;
		right: auto;
		padding: 0;
		text-align: center;
	}
}

.c-modal-rotator__rotator-helptext {
	display: block;
	position: absolute;
	left: 10px;
	top: 10px;

	@include bp('medium') {
		display: none;
	}
}