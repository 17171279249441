// OBJECT.CONTROL

.o-control {
	margin: $lsu 0;
	@include bp("large") {
		margin: $hsu 0;
	}
}

.o-control__heading {
	@include font-32h;
	margin-bottom: $bsu;
}

.o-control__figcaption {
	@include ui-border(bottom);
	@include font-12;
	padding: $bsu 0;
}
