﻿// COMPONENT.BREADCRUMB

.c-breadcrumb {
	padding-top: $bsu;
	padding-bottom: $bsu;
	background: $grey--light;

	.c-share + & {
		@include bp("large") {
			margin-top: $hsu;
		}
	}
}

.c-breadcrumb__link,
.c-breadcrumb__current {
	@include link-transition;
	&:hover,
	&:focus {
		color: $brand;
	}
	@include font-12;
}

.c-breadcrumb__link {
	color: $black;
}

.c-breadcrumb__current {
	color: $grey--dark;
}