﻿// OBJECT.FLAG

.o-flag {
	display: block;
	// The flag images are square, and include white space
	width: 24px;
	height: 24px;
	background-size: contain;
	background-repeat: no-repeat;
}

.o-flag--ca {
	background-image: url("/content/images/interface/flags/ca.png")
}

.o-flag--hr {
	background-image: url("/content/images/interface/flags/hr.png")
}

.o-flag--fr {
	background-image: url("/content/images/interface/flags/fr.png")
}

.o-flag--de {
	background-image: url("/content/images/interface/flags/de.png")
}

.o-flag--jp {
	background-image: url("/content/images/interface/flags/jp.png")
}

.o-flag--no {
	background-image: url("/content/images/interface/flags/no.png")
}

.o-flag--pl {
	background-image: url("/content/images/interface/flags/pl.png")
}

.o-flag--ro {
	background-image: url("/content/images/interface/flags/ro.png")
}

.o-flag--ru {
	background-image: url("/content/images/interface/flags/ru.png")
}

.o-flag--si {
	background-image: url("/content/images/interface/flags/si.png")
}

.o-flag--sk {
	background-image: url("/content/images/interface/flags/sk.png")
}

.o-flag--es {
	background-image: url("/content/images/interface/flags/es.png")
}

.o-flag--gb {
	background-image: url("/content/images/interface/flags/gb.png")
}

.o-flag--ua {
	background-image: url("/content/images/interface/flags/ua.png")
}

.o-flag--us {
	background-image: url("/content/images/interface/flags/us.png")
}

.o-flag--kz {
	background-image: url("/content/images/interface/flags/kz.png")
}

.o-flag--nz {
	background-image: url("/content/images/interface/flags/nz.png")
}

.o-flag--eu {
	background-image: url("/content/images/interface/flags/eu.png")
}

.o-flag--world {
	background-image: url("/content/images/interface/flags/world.png")
}
