// COMPONENT.HISTORY

.c-history__intro {
	@include font-18b;
}

.c-history__text {
	@include font-14;
}

.c-history__rotator-timeline {
	padding: $lsu 0;

	.flickity-button {
		color: $black;
		.flickity-button-icon {
			width: 50%;
			height: 50%;
			@include abs-center;
		}

		&.previous {
			right: 100%;
			left: auto;
		}

		&.next {
			left: 100%;
			right: auto;
		}
	}
}

.c-history__rotator-timeline-layout {
	@include flex-extend;
	align-items: center;
	justify-content: space-between;
}

.c-history__rotator-timeline-arrow {
	@include button-reset;
}

.c-history__rotator-timeline-list {
	@include flex-extend;

	&.flickity-enabled {
		display: block;
	}

	width: calc(100% - 88px);
	margin: 0 auto;
}

.c-history__rotator-timeline-item {
	@include flex-extend;
	justify-content: center;
	width: percentage(1/8);
	min-width: 150px;
}

.c-history__rotator-timeline-item-button {
	@include button-reset;
	display: block;
	padding: $bsu;

	@include font-32;

	@include link-transition;
	color: $grey--dark;
	&:hover,
	&:focus,
	&[aria-current="true"] {
		color: $brand;
	}
}

.c-history__rotator-slide-list {
	@include list-reset;
	max-width: $layout-wrapper-max-width - ($bsu * 2);
	margin: 0 auto;

	@include bp-below("medium") {
		.flickity-button {
			top: #{(9/16) / 2 * 100}vw;
		}
	}
}

.c-history__rotator-slide {
	width: 100%;

	@include bp("medium") {
		position: relative;
		z-index: 1; // Create a stacking context

		&::after {
			content: "";

			position: absolute;
			z-index: 2;
			bottom: 0;
			left: 0;
			right: 0;

			background: linear-gradient(to top, rgba($black, 1) 0%, rgba($black, 0) 100%);
			height: 200px;
			max-height: 100%;

			pointer-events: none;
		}
	}
}

.c-history__rotator-slide-image {
	position: relative;
	z-index: 1;

	display: block;
	width: 100%;
}

.c-history__rotator-slide-body {
	@include bp("medium") {
		position: absolute;
		z-index: 3;
		bottom: 0;
		right: 50%;
		transform: translateX(50%);
		width: 100%; // Relying on o-layout-wrapper to apply max-width

		@include flex-extend;
		align-items: flex-start;

		padding-bottom: $lsu;
	}
}

.c-history__rotator-slide-title {
	@include font-100h;
	color: $grey--dark;
	margin-top: $bsu;
	@include bp("medium") {
		color: $white;

		margin-top: -10px; // This font has weird line height
		margin-right: $lsu;
	}
}

.c-history__rotator-slide-text {
	@include font-18;
	@include bp("medium") {
		color: $white;
	}
}
