﻿// CONTROL.FILTER(in Listing)

.c-filter {
	margin-bottom: $lsu;
}

.c-filter__head {
	@include flex-extend;
	flex-wrap: wrap;

	@include bp("medium") {
		flex-wrap: nowrap;
	}
}

.c-filter__trigger {
	@include button-reset;

	@include flex-extend;
	align-items: center;
	justify-content: space-between;
	padding: $bsu;

	background: $black;
	color: $white;

	width: 100%;
	@include bp("medium") {
		width: percentage(1/3);
	}
}

.c-filter__title {
	@include font-14h;
	margin-top: -0.2em; // Adjust for weird line height of this font

	&::before {
		@include font-icon;
		content: $iconf-filter;
		font-size: 18px;

		display: inline-block;
		vertical-align: middle;

		margin-right: $ssu;
	}
}

.c-filter__trigger-hide,
.c-filter__trigger-show {
	display: block;
	margin-left: $bsu;

	@include font-10;
}

.c-filter__trigger-hide {
	display: none;
}

.c-filter__search {
	position: relative;

	width: 100%;
	margin-top: $bsu;
	@include bp("medium") {
		margin-top: 0;
		width: percentage(2/3);
		padding-left: $bsu;
	}
}

.c-filter__search-input {
	display: block;
	width: 100%;
	height: 100%;
	padding: $bsu #{$bsu + 60px} $bsu $bsu;

	border: 1px solid $grey--dark;
	@include font-14;
}

.c-filter__search-submit {
	@include button-reset;

	@include link-brand;
	@include link-transition;
	font-size: 25px;

	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	width: 60px;

	@include flex-extend;
	align-items: center;
	justify-content: center;
}

.c-filter__body {
	margin: $bsu 0;
	background: $grey--lightest;
	padding: $bsu;

	position: relative;

	display: none;
}
.c-filter[aria-expanded="true"] {
	.c-filter__trigger-hide {
		display: block;
	}
	.c-filter__trigger-show {
		display: none;
	}
	.c-filter__body {
		display: block;
	}
}

.c-filter__close {
	@include button-reset;

	position: absolute;
	top: 5px;
	right: 5px;

	width: 44px;
	height: 44px;
	@include flex-extend;
	align-items: center;
	justify-content: center;

	@include link-brand;
	@include link-transition;

	&::before {
		@include font-icon;
		content: $iconf-close;
	}
}

.c-filter__group-list {
	@include list-reset;
	@include flex-extend;
	flex-wrap: wrap;

	margin-top: -$bsu;
	margin-left: -$bsu;
}

.c-filter__group {
	width: 100%;
	@include bp("small") {
		width: 50%;
	}
	@include bp("medium") {
		width: percentage(1/3);
	}

	padding-top: $bsu;
	padding-left: $bsu;
}

.c-filter__group-title {
	display: block;
	margin-bottom: $ssu;

	@include font-14h;
}

.c-filter__item-list {
	@include list-reset;
}

.c-filter__item {
	margin-bottom: $tsu;
	&:last-child {
		margin-bottom: 0;
	}
}

.c-filter__item-label {
	@include flex-extend;
	align-items: center;

	cursor: pointer;
}

.c-filter__item-label-text {
	@include font-14;

	display: inline-block;
	margin-left: $ssu;
}

.c-filter__controls {
	@include flex-extend;
	justify-content: flex-end;

	margin-top: $lsu;
}

.c-filter__control {
	margin-left: $ssu;
	&:first-child {
		margin-left: 0;
	}
}


.c-filter__summary {
	display: block;
	margin: $bsu 0 $lsu;

	@include font-14;
}

.c-filter__summary-tag {
	@include font-14b;
	color: $black;

	display: inline-block;
	padding-right: 7px;

	position: relative;
	&::after {
		position: absolute;
		top: 0;
		right: 0;

		@include font-icon;
		content: $iconf-close;

		font-size: 5px;
	}

	margin-right: $ssu;
	&:last-child {
		margin-right: 0;
	}
}





// Old
.c-filter {}

.c-filter__form {

}

.c-filter__field {
	margin-bottom: $bsu;

	@include bp('large') {
		display: inline-flex;
		align-items: center;
	}
}

.c-filter__label {
	display: block;
	@include font-14b;
	margin-bottom: $ssu/2;

	@include bp('large') {
		margin-bottom: 0;
		margin-right: $ssu;
	}
}

.c-filter__dropdown {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	width: 100%;

	select {
		@include ui-border('all');
		padding: $ssu $lsu $ssu $ssu;
		margin: 0;
		width: 100%;
		background: $grey--light;
		/*Hiding the select arrow for firefox*/
		-moz-appearance: none;
		/*Hiding the select arrow for chrome*/
		-webkit-appearance: none;
		/*Hiding the select arrow default implementation*/
		appearance: none;

		&::-ms-expand {
			display: none;
		}
	}

	&:before {
		position: absolute;
		display: block;
		font-family: 'font-icons';
		content: "\e903";
		right: $bsu;
		top: 50%;
		transform: translateY(-50%);
		color: $brand;
		pointer-events: none;
	}

	@include bp('large') {
		width: auto;
		min-width: 200px;
		margin-right: $bsu;
	}
}
.c-filter__input-wrap {
	position: relative;

}

.c-filter__input {
	@include ui-border('all');
	padding: $ssu $lsu $ssu $ssu;
	margin: 0;
	width: 100%;
	background: $grey--light;
}

.c-filter__input-icon {
	position: absolute;
	right: $bsu;
	top: 50%;
	transform: translateY(-50%);
	color: $brand;
	pointer-events: none;
}

.c-filter__expand {
	.c-filter__expand-body {
		display: none;
	}

	.c-filter__heading {
		@include font-24b;
		cursor: pointer;
	}

	&[aria-expanded="true"] {
		.c-filter__expand-body {
			display: block;
		}

		.c-filter__heading {
			margin-bottom: 0.7em;
		}
	}

	@include bp('large') {
		.c-filter__expand-body {
			display: block;
		}

		.c-filter__heading {
			margin-bottom: 0.7em;
			cursor: default;
		}
	}
}

// filter in sidebar
.c-filter__aside {
	@include ui-border('all');



	.c-filter__form {
		padding: $bsu;
	}

	.c-filter__field {
		display: block;
		width: 100%;
	}

	.c-filter__dropdown {
		margin-right: 0;
		width: 100%;
	}

	.c-filter__label {
		margin-bottom: $ssu/2;
	}

	.c-filter__label-inline {
		display: inline-block;
		margin-top: $ssu;
		vertical-align: middle;
		@include font-14;
		width: 50px;
	}

	.c-filter__label-inline + .c-filter__input-wrap {
		width: calc(100% - 55px);
		display: inline-block;
		vertical-align: middle;
		margin-top: $ssu;
	}

	.c-filter__controls {
		@include flex-extend;
		flex-direction: row-reverse;
		justify-content: space-between;
		align-items: center;
	}
}

.c-filter__reset {
	color: $brand;
}
