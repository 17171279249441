// COMPONENT.HERO

$hero-bp: "large";

.c-hero {
}

.c-hero__layout {
    max-width: 1920px;
    padding: 0 20px;
    margin: 0 auto;
}

/////////////
// PRIMARY //
/////////////
.c-hero__primary {
    margin: 0 0 $bsu 0;

    @include bp($hero-bp) {
        margin: 0 0 $lsu 0;
        @include flex-extend;
        align-items: stretch;
    }

    position: relative;
    z-index: 1;
}

.c-hero__body {
    @include flex-extend;
    flex-direction: column;
    overflow: visible;
    padding: 20px 0;

    @include bp($hero-bp) {
        padding: 120px 20px 0;
        width: 350px;
    }

    @include bp("extra-large") {
        width: 450px;
    }
}

.c-hero__title {
    @include bp($hero-bp) {
        margin-right: -400px;
    }

    margin-bottom: $ssu;

    @include bp("medium") {
        margin-bottom: $bsu;
    }

    @include bp("large") {
        margin-bottom: $lsu;
    }

    color: $brand;
    @include font-100h;
    position: relative;
    z-index: 2;
}

.c-hero__text {
    display: none;

    @include bp($hero-bp) {
        display: block;
        flex-grow: 1;
    }

    @include font-18b;
}

.c-hero__scroll-icon {
    display: none;

    @include bp($hero-bp) {
        display: block;
    }

    margin-top: $lsu;
    text-align: center;
    color: $brand;
    font-size: 24px;
}

.c-hero__figure {
    margin-right: -20px;

    @include bp($hero-bp) {
        margin-left: $lsu;
        margin-right: 0;
    }

    flex-grow: 1;
    position: relative;
    overflow: hidden;

    &::before {
        content: "";
        position: absolute;
        z-index: 1;
        top: -1px;
        left: -1px;
        border-top: 40px solid $white;
        border-left: #{40px * $brand-angle-multiplier} solid $white;
        border-bottom: 40px solid transparent;
        border-right: #{40px * $brand-angle-multiplier} solid transparent;

        @include bp("medium") {
            border-top-width: 100px;
            border-left-width: #{100px * $brand-angle-multiplier};
            border-bottom-width: 100px;
            border-right-width: #{100px * $brand-angle-multiplier};
        }
    }
}

.c-hero__image {
    display: block;
    width: 100%;

    @include bp($hero-bp) {
        height: 100%;
        object-fit: cover;
    }
}

///////////////
// SECONDARY //
///////////////
.c-hero__secondary {
    margin: $lsu 0;

    @include bp("large") {
        margin: $hsu 0;
    }

    @include bp($hero-bp) {
        @include flex-extend;
    }
}

.c-hero__secondary-title {
}

.c-hero__secondary-title-text {
    color: $brand;
    @include font-50;
    margin-bottom: $bsu;

    @include bp($hero-bp) {
        padding: 0 20px;
        width: 350px;
        margin-bottom: 0;
    }

    @include bp("extra-large") {
        width: 450px;
    }
}

.c-hero__secondary-introduction {
    flex: 0 1 auto;

    @include font-18b;

    a {
        @include link-brand;
    }

    @include bp($hero-bp) {
        margin-top: 4px; // Align better with secondary title
        margin-left: 40px;
    }
}
