﻿// CONTROL.CTA-VERBOSE-GERARD

.o-layout-homepage,
.o-layout-main {
	.c-cta-verbose__bg {
		@include brand-gradient;
		padding: 5px;
	}

	.c-cta-verbose__body {
		background-color: $cream;
		border: none;

		.c-cta-verbose__link {
			background: $brand--light;
			box-shadow: none;
			&:hover,
			&:focus {
				background: $white;
				box-shadow: inset 0 0 0 1px currentColor;
			}
		}
	}

	.c-themed-controls .c-cta-verbose__body {
		background-color: $white;
	}

	.c-cta-verbose__body--dark {
		@include brand-gradient;
		border: none;

		.c-cta-verbose__link {
			background: $white;
			box-shadow: none;
			&:hover,
			&:focus {
				background: $brand;
				box-shadow: none;
			}
		}
	}
}

.o-layout-aside .c-cta-verbose__body {
	&::before {
		display: none;
	}
}
