﻿// CONTROL.ICON-FEATURES

$icon-feature-mob-width: 230px;

.c-icon-features__items {
	@include flex-extend;
	@include flex-wrap;
	@include list-reset;
	width: 100%;
	justify-content: center;
}

.c-icon-features__item {
	@include flex-extend;
	flex-direction: column;
	width: 100%;
	max-width: $icon-feature-mob-width;
	text-align: center;
	padding: $bsu 0;

	@include bp('small') {
		padding: $bsu;
	}

	@include bp('medium') {
		max-width: 380px;
	}
	@include bp('large') {
		width: 25%;
		max-width: 390px;
	}
}

.c-icon-features__item-icon {
	display: block;
	position: relative;
	margin: 0 auto;
	width: 200px;

	@include bp($icon-feature-mob-width * 2) {
		height: 200px;
	}
}

.c-icon-features__item-figure {
	background-color: transparent !important;

	@include bp($icon-feature-mob-width * 2) {
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		padding-bottom: 100% !important;
	}
}

.c-icon-features__item-title {
	@include font-14hb;
	padding: 0 $ssu;
	margin-top: 1em;
	margin-bottom: 0.7em;

	&:last-child {
		margin-bottom: 0;
	}
}

.c-icon-features__item-abstract {
	@include font-14;
	justify-content: flex-end;
	padding: 0 $ssu;
	margin-bottom: 0;
	text-wrap: balance;
}

.c-icon-features__item-link {
	color: inherit;
}

.c-icon-features__item-link img {
	transition: transform 0.25s ease-in-out;
}

.c-icon-features__item-link:hover {
	color: $brand--alt;
	text-decoration: underline;
}

.c-icon-features__item-link:hover img {
	transform: scale(1.05);
}
