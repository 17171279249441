﻿// THEME

$white: #fff;
$black: #000;

$grey--lightest: #f9f9f9;
$grey--lighter: #f0f0f0;
$grey--light: #dee3e3;
$grey: #aeafb2;
$grey--dark: #717475;
$grey--darkest: #494c4d;

$green: #6abe4a;
$green--dark: #06963c;

$blue: #00b3c4;

$red: #c32032;

$brand: #003c83; // Gerard Blue
$brand--alt: #0099b8; // Gerard Sky Blue
$brand--light: #2cbdc1; // Gerard Ice Green

$cream: #feeed3;

$yellow--pale: #f3eed3;
$blue--pale: #e5ebf2;
$green--pale: #e0f5f6;

@mixin brand-gradient($opacity: 1) {
	background: linear-gradient(90deg, rgba($brand, $opacity) 20%, rgba($brand--light, $opacity) 100%);
}

// Shared non-theme colours, e.g. alert, errors
$success: #008000;
$success--light: lighten($success, 40);
$warning: #ffa500;
$warning--light: lighten($warning, 40);
$error: #f00;
$error--light: lighten($error, 40);

// Assign colours to common variables
$body-color: $grey--darkest;
$base-ui-color: $grey;

$brand-angle: 0deg;
$brand-angle-multiplier: 1; // cot(90deg)

body {
	// Override Umbraco's preview style setting a body background colour
	background: $white;
}
