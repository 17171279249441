// OBJECT.LOADER

$loader-delay: 0.5s;

@keyframes spin-centred-cw {
	0% {
		transform: translate(50%, -50%) rotate(0deg);
	}

	100% {
		transform: translate(50%, -50%) rotate(360deg);
	}
}

@mixin loader-spin($size: 70px) {
	// ::before is an overlay to block interaction
	&::before {
		content: "";
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background: rgba($white, 0.8);

		opacity: 0;
		animation: 0.5s $loader-delay ease fadein forwards;
	}

	// ::after is the loader itself
	&::after {
		content: "";
		@include abs-center;
		z-index: 1;

		opacity: 0;
		animation: 0.5s $loader-delay ease fadein forwards,
		           1s linear infinite spin-centred-cw;

		width: $size;
		height: $size;
		border-radius: 100%;

		border: $size/7 solid rgba($brand, 0.1);
		border-top-color: $brand;
	}
}

.o-loader {
	position: relative;

	@include loader-spin;
	cursor: wait;
}

.o-loader--immediate {
	&::before {
		animation: 0.5s ease fadein forwards;
	}
	&::after {
		animation: 0.5s ease fadein forwards,
		           1s linear infinite spin-centred-cw;
	}
}
