// COMPONENT.PRODUCT

.c-product {}

.c-product__hero {
	width: 100%;

	padding-bottom: $lsu;
	@include bp("large") {
		padding-bottom: $hsu;

		@include flex-extend;
	}
}

.c-product__hero-detail-layout,
.c-product__hero-image-layout {
	@include bp("large") {
		width: 50%;
	}
}

.c-product__hero-detail {
	@include bp-below("large") {
		padding: 0 $bsu;
	}
	@include bp("large") {
		@include layout-split(left, 50%);
		padding-right: $lsu;
		padding-left: $lsu;
	}
}

.c-product__hero-image {
	display: none;
	@include bp("large") {
		display: block;
		margin-top: 10px; // Make up for weird font height
	}
	@include bp("extra-large") {
		margin-top: 16px; // Make up for weird font height
	}
}
.c-product__hero-image--mobile {
	margin-bottom: $bsu;
	@include bp("large") {
		display: none;
	}
}


.c-product__title-split {
	@include flex-extend;
	align-items: flex-start;
	justify-content: flex-start;
}

.c-product__title {
	@include font-50h;
	margin-right: $lsu;
}

.c-product__title-brand {
	display: block;
	transform: translateY(-50%);
	max-height: 50px;

	margin-top: 17px;
	@include bp("medium") {
		margin-top: 25px;
	}
	@include bp("large") {
		margin-top: 34px; // Adjust for title line height
	}
}

.c-product__subtitle {
	display: block;
	margin: 0 0 30px;

	@include font-32b;
	color: $brand;
}

.c-product__description {}

.c-product__description-text {
	// The expand button will come after it
	> p:nth-last-child(2) {
		margin-bottom: 0;
	}
}

.c-product__description-expand {
	@include button-reset;
	@include link-brand;
	@include link-transition;

	@include font-14;

	&::before {
		@include font-icon;
		content: $iconf-plus;
		font-size: 0.8em;

		display: inline-block;
		vertical-align: middle;

		margin-right: $tsu;
	}

	display: none;
}

.c-product__description {
	&[aria-expanded="false"] {
		.c-product__description-text {
			> :nth-child(n+2) {
				display: none;
			}
		}

		.c-product__description-expand:nth-child(n+3) {
			display: block;
		}
	}

	&[aria-expanded="true"] {
		.c-product__description-expand {
			display: none;
		}

		p:nth-last-child(2) {
			margin-bottom: 0;
		}
	}
}

.c-product__finishes {}

.c-product__finishes-item {
	margin-top: $bsu;
}

.c-product__finishes-name {
	display: block;
	margin-bottom: $ssu;

	@include font-14h;
}

.c-product__finishes-colours {
	@include list-reset;
	@include flex-extend;
	@include flex-wrap;
	margin-left: -$ssu;
	margin-top: -$ssu;
}

.c-product__finishes-colours-item {
	padding-left: $ssu;
	padding-top: $ssu;

	width: 110px;

	&:hover {
		.c-product__finishes-colours-name {
			color: $brand;
		}
	}
}

.c-product__finishes-colours-image-container {
	position: relative;
	overflow: hidden;
}

.c-product__finishes-colours-image {}

.c-product__finishes-colours-name {
	display: block;
	margin-top: $ssu;
	transition:color 0.2s ease-out;
	@include font-12;
}

.c-product__finishes-button {
	margin: $bsu 0;
}

.c-product__finishes-disclaimer {
	margin: $bsu 0;
	display: block;

	@include font-14;

	.c-product__finishes-button + & {
		margin-top: 0;
	}

	> p:last-child {
		margin-bottom: 0;
	}
}


.c-product__hero-gallery {
	position: relative;
	z-index: 1; // Create stacking context

	&::before {
		content: "";
		position: absolute;
		z-index: 2;
		top: 0;
		left: 0;

		border-top: 40px solid $white;
		border-left: #{$brand-angle-multiplier * 40px} solid $white;

		border-bottom: 40px solid transparent;
		border-right: #{$brand-angle-multiplier * 40px} solid transparent;

		@include bp("medium") {
			border-top-width: 80px;
			border-left-width: #{$brand-angle-multiplier * 80px};

			border-bottom-width: 80px;
			border-right-width: #{$brand-angle-multiplier * 80px};
		}

		@include bp("large") {
			border-top-width: 40px;
			border-left-width: #{$brand-angle-multiplier * 40px};

			border-bottom-width: 40px;
			border-right-width: #{$brand-angle-multiplier * 40px};
		}

		@include bp("extra-large") {
			border-top-width: 80px;
			border-left-width: #{$brand-angle-multiplier * 80px};

			border-bottom-width: 80px;
			border-right-width: #{$brand-angle-multiplier * 80px};
		}
	}

	cursor: pointer;
	&:hover,
	&:focus {
		.c-product__hero-gallery-poster {
			transform: scale(1.1);
		}

		.c-product__hero-gallery-button {
			color: $white;
			background: $brand;
		}
	}
}

.c-product__hero-gallery-poster-container {
	position: relative;
	overflow: hidden;
	z-index: 1;
}

.c-product__hero-gallery-poster {
	@include link-transition;
}

.c-product__hero-gallery-button {
	position: absolute;
	z-index: 3;
	bottom: $bsu;
	left: $bsu;
}

.c-product__tools {
	@include flex-extend;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: flex-start;
	max-width: 600px;
	gap:$tsu;

	margin: #{$lsu - $ssu} 0 $lsu;

	
	@include bp("large") {
		max-width: none;
	}
}

.c-product__tools-button {
	margin-top: $ssu;
}

.c-product__tools-button--primary {
}


.c-product__section {
	padding: $lsu 0;
	border-top: 1px solid $grey--light;

	@include bp("large") {
		padding: $hsu 0;
	}
}
.c-product__section--grey {
	border-top: none;
	background: $grey--lightest;

	+ .c-product__section {
		border-top: none;
	}
}

.c-product__section-title {
	@include font-32;

	display: block;
	margin-bottom: $bsu;
}

.c-product__section-text {
	display: block;
	margin-bottom: $lsu;
}


// Specifications
.c-product__specifications-title {}

.c-product__specifications-list {
	margin: 0;
	padding: 0;
}

.c-product__specifications-item {
	@include flex-extend;
	margin-bottom: $ssu;
}

.c-product__specifications-term {
	width: 170px;
	@include font-14b;
}

.c-product__specifications-description {
	@include font-14;
}


// Guarantee
.c-product__guarantee-list {
	@include list-reset;
	@include flex-extend;
	flex-wrap: wrap;

	margin-top: -$bsu;
	margin-left: -$bsu;
}

.c-product__guarantee-item {
	padding-top: $bsu;
	padding-left: $bsu;

	width: 148px; // Maximise space at full width
}

.c-product__guarantee-link {
	display: block;
}

.c-product__guarantee-image {
	display: block;
	max-width: 100%;
}
