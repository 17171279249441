// COMPONENT.TOOLTIP

$tooltip-trigger-size: 16px;
$tooltip-arrow-size: 10px;
$tooltip-width: 320px;

.c-tooltip {
	display: inline-block;
	height: $tooltip-trigger-size;

	&[aria-expanded="true"] {
		.c-tooltip__body {
			display: block;
		}
	}

	&.c-tooltip--left {
		.c-tooltip__body {
			margin-left: -($tooltip-arrow-size + $ssu);
			transform: translateX(-100%) translateY(-50%);

			&::before {
				left: auto;
				right: -$tooltip-arrow-size;
				border-right: 0;
				border-left: $tooltip-arrow-size solid $black;
			}
		}
	}
}

.c-tooltip__trigger {
	position: relative;
	display: inline-block;
	width: $tooltip-trigger-size;
	height: $tooltip-trigger-size;
	border: 0;
	border-radius: 100%;
	text-decoration: none;
	line-height: 1;
	color: $white;
	background: $brand;

	&::before {
		content: "i";
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		font-size: ($tooltip-trigger-size / 1.5);
		text-align: center;
		line-height: $tooltip-trigger-size;
	}

	&:hover,
	&:focus {
		background: $brand--alt;
	}
}

.c-tooltip__body {
	position: absolute;
	z-index: $z-tooltip;
	display: none;
	width: $tooltip-width;
	max-width: calc(100% - #{$tooltip-trigger-size + $tooltip-arrow-size + $ssu + $lsu});
	padding: $bsu $lsu $bsu $bsu;
	margin-top: -($ssu + 3px);
	margin-left: $tooltip-arrow-size + $ssu;
	line-height: lh($base-line-height, $base-font-size);
	color: $white;
	background: $brand;
	transform: translateX($tooltip-trigger-size) translateY(-50%);

	&::before {
		content: '';
		position: absolute;
		width: 0;
		height: 0;
		left: -$tooltip-arrow-size;
		top: 50%;
		margin-top: -$tooltip-arrow-size;
		margin-left: 0;
		border-top: $tooltip-arrow-size solid transparent;
		border-right: $tooltip-arrow-size solid $black;
		border-bottom: $tooltip-arrow-size solid transparent;
		border-left: 0;
	}

	.c-tooltip__close + * {
		margin-top: 0;
	}

	> :last-child {
		margin-bottom: 0;
	}

	img {
		max-width: 100%;
	}
}

.c-tooltip__close {
	@include button-reset;
	@include font-icon;
	position: absolute;
	right: $ssu;
	top: $ssu;
	color: $grey;

	&:hover,
	&:focus {
		text-decoration: none;
		color: $white;
	}

	&::before {
		content: $iconf-close;
	}
}
