﻿// CONTROL.IMAGE-FEATURE-GERARD

[class*="c-image-feature__tri"] {
	&::before {
		// Use 60deg brand angle multiplier - cotan(60deg)
		padding-bottom: percentage(1 / 0.5774);
	}

	@include bp-below("medium") {
		display: none;
	}
}

.c-image-feature__tri-1 {
	&::before {
		// background-color: $grey--lighter;
	}
}

.c-image-feature__tri-3 {
	&::before {
		// background-color: $grey--lightest;
	}
}

// Right image variations
.c-image-feature--right {
	@include bp("medium") {
		flex-flow: row-reverse;
		.c-image-feature__tri-2 {
			width: 25%;
		}
	}
}

// Only output on right image option
.c-image-feature__tri-2 {
	width: 55%;

	&::before {
		@include brand-gradient;
	}
}