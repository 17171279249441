﻿// CONTROL.PODS


.c-pod {
	@include flex-extend;
	flex-direction: column;
}

.c-pods__item > .c-pod {
	height: 100%;
}

.c-pod__image-container {
	position: relative;
	overflow: hidden;
	height: 315px;

	&::before {
		content: "";

		position: absolute;
		z-index: 1;
		top: -1px;
		left: 0;

		border-top: 17px solid $white;
		border-left: #{$brand-angle-multiplier * 17px} solid $white;

		border-bottom: 17px solid transparent;
		border-right: #{$brand-angle-multiplier * 17px} solid transparent;
	}

	.c-themed-controls &::before {
		border-top-color: $grey--lightest;
		border-left-color: $grey--lightest;
	}
}

.c-pod__image {
	@include link-transition;
	height: 315px;

	.c-pod__image-container & {
		padding-bottom: 0;
	}
}

.c-pod__body {
	padding: $bsu;
	background: $grey--lightest;

	flex-grow: 1;
	@include flex-extend;
	flex-direction: column;

	.c-themed-controls & {
		background: $white;
	}
}

.c-pod__heading {
	display: block;

	color: $brand;
	@include font-20b;
	@include link-transition;
}

.c-pod__subtitle {
	margin-top: $tsu;
	display: block;
	margin-bottom: $bsu;

	@include font-12;
	color: $grey--dark;
}

.c-pod__text {
	flex-grow: 1;

	display: block;
	margin-top: $bsu;

	color: $body-color;
	@include font-14;
}

.c-pod__link {
	display: block;
	margin-top: $bsu;

	@include font-14;
	color: $brand;
	@include link-transition;

	&::before {
		@include font-icon;
		content: $iconf-arrow-right;

		display: inline-block;
		vertical-align: middle;
		margin-right: $tsu;
	}
}

a.c-pod {
	.c-pod__head {
		position: relative;
		overflow: hidden;

		&::before,
		&::after {
			@include link-transition;
		}
		&::before {
			@include font-icon;
			content: $iconf-arrow-right;

			position: absolute;
			z-index: 1;
			right: 0;
			bottom: 0;
			color: $brand;
			font-size: 24px;

			transform: rotate(35deg) translate(60px, 0) rotate(-35deg);
		}
		&::after {
			content: "";
			position: absolute;
			bottom: 0;
			right: 0;

			display: block;
			width: 0;
			height: 0;

			border-bottom: 0 solid $white;
			border-top: 0 solid transparent;
			border-right: 0 solid $white;
			border-left: 0 solid transparent;
		}
	}
	&:hover,
	&:focus {
		.c-pod__head {
			&::before {
				opacity: 1;

				transform: translate(0, 0);
			}
			&::after {
				border-bottom-width: 27px;
				border-top-width: 27px;
				border-right-width: #{27px * $brand-angle-multiplier};
				border-left-width: #{27px * $brand-angle-multiplier};
			}
		}

		.c-pod__heading,
		.c-pod__link {
			color: $brand--alt;
		}
		.c-pod__image {
			transform: scale(1.1);
		}
	}

	&[href^="http"] {
		.c-pod__head {
			&::before {
				opacity: 1;
				transform: none;

				bottom: $ssu;
				right: $ssu;

				width: 40px;
				height: 40px;
				font-size: 20px;
				border-radius: 100%;

				@include flex-extend;
				align-items: center;
				justify-content: center;

				background: $brand;
				color: $white;
			}
			&::after {
				display: none;
			}
		}
		&:hover,
		&:focus {
			.c-pod__head {
				&::before {
					transform: none;
					background: $brand--alt;
				}
			}
		}
	}
}
