// OBJECT.BTN

// Shared button styles

.o-btn {
	@include link-transition;
	display: inline-block;
	padding: 7px 12px;
	border: 0;
	text-align: center;
	text-decoration: none;
	vertical-align: middle;
	cursor: pointer;

	[class*=" iconf"],
	[class^="iconf"] {
		display: inline-block;
		vertical-align: middle;
		margin-right: 0.5em;
	}
}

.o-btn--full {
	display: block;
	width: 100%;
}

.o-btn--reset {
	padding: 0;
	border: 0;
	background: none;
}

.o-btn--no-break {
	white-space: nowrap;
}


//////////////////
// Button sizes //
//////////////////
.o-btn--priority {
	@include font-18b;
	padding: 24px 36px;
}

.o-btn--large {
	@include font-16b;
	padding: 18px 27px;
}

.o-btn--medium {
	@include font-14b;
	padding: 12px 18px;
}

.o-btn--small {
	@include font-14b;
	padding: 10px 15px;
}

///////////////////
// Button styles //
///////////////////
.o-btn--brand {
	background: $brand;
	box-shadow: inset 0 0 0 1px $brand;
	color: $white;

	&:hover,
	&:focus {
		background: $white;
		box-shadow: inset 0 0 0 1px $brand;
		color: $brand;
	}
}

// Reversed brand
.o-btn--secondary {
	background: $white;
	box-shadow: inset 0 0 0 1px $brand;
	color: $brand;

	&:hover,
	&:focus {
		background: $brand;
		box-shadow: inset 0 0 0 1px $brand;
		color: $white;
	}
}

// No background or border until hover
.o-btn--tertiary {
	background: transparent;
	color: $brand;

	&:hover,
	&:focus {
		box-shadow: inset 0 0 0 1px $brand;
	}
}

