﻿// BASE.TYPOGRAPHY


// Style declarations
//
//	.c-component__heading {
//		@include font-12;
//	}

@mixin font-100h {
	font-size: 50px;
	line-height: 1;

	@include bp("medium") {
		font-size: 70px;
		line-height: 0.82;

		[lang="ja"] & {
			line-height: 1.2;
		}

		[lang="lt"] & {
			line-height: 1.2;
		}
	}

	@include bp("large") {
		font-size: 100px;
	}

	font-weight: $weight--bold;
	font-family: $brand-font-family;
	text-transform: uppercase;
	letter-spacing: 0.02em;
}

@mixin font-100h-only {
	font-size: 100px;
	line-height: 1;
	font-weight: $weight--bold;
	font-family: $brand-font-family;
	text-transform: uppercase;
	letter-spacing: 0.02em;
}

@mixin font-70h {
	font-size: 70px;
	line-height: 0.82;
	font-weight: $weight--bold;
	font-family: $brand-font-family;
	text-transform: uppercase;
	letter-spacing: 0.02em;
}

@mixin font-70hn {
	font-size: 28px;
	line-height: 1.12;

	@include bp(425px) {
		font-size: 36px;
	}

	@include bp("medium") {
		font-size: 44px;
	}

	@include bp("extra-large") {
		font-size: 70px;
	}

	font-weight: $weight--normal;
	font-family: $brand-font-family;
	letter-spacing: 0.02em;
}

@mixin font-50 {
	font-size: 36px;
	line-height: 1.12;
	@include bp("extra-large") {
		font-size: 50px;
	}

	font-weight: $weight--normal;
}

@mixin font-50h {
	font-size: 24px;
	line-height: 1.12;

	@include bp(425px) {
		font-size: 32px;
	}

	@include bp("medium") {
		font-size: 36px;
	}

	@include bp("extra-large") {
		// 50px looks weirdly mis-aligned, so use 51px instead
		font-size: 51px;
	}

	font-weight: $weight--bold;
	font-family: $brand-font-family;
	letter-spacing: 0.02em;
}

@mixin font-50hn {
	font-size: 24px;
	line-height: 1.12;

	@include bp(425px) {
		font-size: 32px;
	}

	@include bp("medium") {
		font-size: 36px;
	}

	@include bp("extra-large") {
		// 50px looks weirdly mis-aligned, so use 51px instead
		font-size: 51px;
	}

	font-weight: $weight--normal;
	font-family: $brand-font-family;
	letter-spacing: 0.02em;
}

@mixin font-40hu {
	font-size: 32px;
	line-height: 1.15;
	@include bp("extra-large") {
		font-size: 40px;
	}

	font-weight: $weight--bold;
	font-family: $brand-font-family;
	text-transform: uppercase;
}

@mixin font-40 {
	font-size: 32px;
	line-height: 1.15;
	@include bp("extra-large") {
		font-size: 40px;
	}
	font-weight: $weight--normal;
}

@mixin font-40b {
	font-size: 32px;
	line-height: 1.15;
	@include bp("extra-large") {
		font-size: 40px;
	}
	font-weight: $weight--bold;
}

@mixin font-36b {
	font-size: 36px;
	font-weight: $weight--bold;
	line-height: 1.15;
}

@mixin font-32 {
	font-size: 32px;
	font-weight: $weight--normal;
	line-height: 1.15;
}

@mixin font-32b {
	font-size: 32px;
	font-weight: $weight--bold;
	line-height: 1.25;
}

@mixin font-32h {
	font-size: 24px;
	line-height: 1.12;

	@include bp(425px) {
		font-size: 32px;
		line-height: 1.15;
	}

	font-weight: $weight--bold;
	font-family: $brand-font-family;
	letter-spacing: 0.02em;
}

@mixin font-30h {
	font-size: 30px;
	line-height: 1.15;
	font-weight: $weight--bold;
	font-family: $brand-font-family;
	letter-spacing: 0.02em;
}

@mixin font-24 {
	font-size: 24px;
	font-weight: $weight--normal;
	line-height: 1.15;
}

@mixin font-24b {
	font-size: 24px;
	font-weight: $weight--bold;
	line-height: 1.15;
}

@mixin font-24h {
	font-size: 16px;

	@include bp("medium") {
		font-size: 24px;
	}

	font-weight: $weight--bold;
	font-family: $brand-font-family;
	letter-spacing: 0.02em;
	line-height: 1.15;
}

@mixin font-20 {
	font-size: 20px;
	font-weight: $weight--normal;
	line-height: 1.15;
}

@mixin font-20-tall {
	font-size: 20px;
	font-weight: $weight--normal;
	line-height: 1.4;
	letter-spacing: 0.01em;
}

@mixin font-20b {
	font-size: 20px;
	font-weight: $weight--bold;
	line-height: 1.15;
}

@mixin font-20h {
	font-size: 20px;
	line-height: 24px;
	font-weight: $weight--bold;
	font-family: $brand-font-family;
	letter-spacing: 0.02em;
}

@mixin font-18 {
	font-size: 18px;
	font-weight: $weight--normal;
	line-height: 1.45;
}

@mixin font-18b {
	font-size: 18px;
	font-weight: $weight--bold;
	line-height: 1.45;
}

@mixin font-16 {
	font-size: 16px;
	font-weight: $weight--normal;
	line-height: 1.5;
	letter-spacing: 0.01em;
}

@mixin font-16b {
	font-size: 16px;
	font-weight: $weight--bold;
	line-height: 1.5;
	letter-spacing: 0.01em;
}

@mixin font-16h {
	font-size: 16px;
	line-height: 1.5;
	font-weight: $weight--bold;
	font-family: $brand-font-family;
	letter-spacing: 0.02em;
}

@mixin font-14hb {
	font-size: 14px;
	line-height: 1.5;
	font-weight: $weight--bold;
	font-family: $brand-font-family;
	letter-spacing: 0.02em;
}

@mixin font-14h {
	font-size: 14px;
	line-height: 1.5;
	font-weight: $weight--normal;
	font-family: $brand-font-family;
	letter-spacing: 0.02em;

	[lang="ja"] {
		font-weight: 700;
	}

	[lang="uk"] &,
	[lang="kk-KZ"] &,
	[lang="ru"] & {
		font-weight: $weight--bold;
	}
}

@mixin font-14n {
	font-size: 14px;
	line-height: 1.5;
	font-weight: $weight--normal;
	font-family: $brand-font-family;
	letter-spacing: 0.02em;
}

@mixin font-14nu {
	@include font-14n;
	text-transform: uppercase;
}

@mixin font-14 {
	font-size: 14px;
	font-weight: $weight--normal;
	line-height: 1.6;
}

@mixin font-14b {
	font-size: 14px;
	font-weight: $weight--bold;
	line-height: 1.6;
}

@mixin font-14bu {
	@include font-14b;
	text-transform: uppercase;
}

@mixin font-12 {
	font-size: 12px;
	font-weight: $weight--normal;
	line-height: 1.15;
}

@mixin font-12b {
	font-size: 12px;
	font-weight: $weight--bold;
	line-height: 1.15;
}

@mixin font-10 {
	font-size: 10px;
	font-weight: $weight--normal;
	line-height: 1.15;
}

@mixin font-10u {
	@include font-10;
	text-transform: uppercase;
}

@mixin font-10b {
	font-size: 10px;
	font-weight: $weight--bold;
	line-height: 1.15;
}

@mixin font-10ub {
	@include font-10b;
	text-transform: uppercase;
}

html {
	font-family: $base-font-family;
	color: $body-color;
}

button,
input,
optgroup,
select,
textarea {
	font-family: $base-font-family;
}

p {
	margin-top: 0;
}
