// COMPONENT.HERO-VIDEO

$hero-bp: "large";

.c-hero-video {
	position: relative;
	overflow: hidden;
}

.c-hero-video__viewport {
	display: none;

	@include bp('large') {
		display: block;
		overflow: hidden;
		width: 100%;
		height: 0;
		padding-bottom: percentage(9/16);
	}

	@include bp('extra-large') {
		height: calc(100vh - 95px);
		padding-bottom: 0;
	}
}

.c-hero-video__scroll-icon {
	display: none;
	position: absolute;
	left: 50%;
	bottom: $bsu;
	font-size: 24px;
	color: $white;

	@include bp('extra-large') {
		display: block;
		animation: bounce 2s infinite;
	}
}

.c-hero-video__figure {
	position: absolute;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	width: 100%;

	@include bp('extra-large') {
		width: auto;
		min-width: 100%;
		min-height: 100%;
	}
}

.c-hero-video__mobile-figure {
	width: 100%;
	overflow: hidden;

	@include bp('large') {
		display: none;
	}
}

.c-hero-video__body {
	width: 100%;
	padding: $bsu $bsu 0;

	@include bp('medium') {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 100%;
		padding: 0 $bsu;
		max-width: $layout-wrapper-max-width;
		transform: translate(-50%, -50%);
	}
}

.c-hero-video__heading {
	@include font-30h;
	position: relative;
	z-index: 2;
	color: $brand;
	display: block;

	@include bp("medium") {
		@include font-70h;
		width: 67%;
	}

	@include bp("extra-large") {
		@include font-100h-only;
	}
}

.c-hero-video__heading--white {

	@include bp("medium") {
		color: $white;
		text-shadow: 2px 2px 8px rgba($black, 0.8);
	}
}

.c-hero-video__btn {
	@include font-14b;
	padding: 10px 15px;

	@include bp('medium') {
		padding: 12px 18px;
	}

	@include bp('large') {
		@include font-16b;
		padding: 18px 27px;
	}
}

.c-hero-video__heading + .c-hero-video__btn {
	margin-top: $ssu * 1.5;

	@include bp('medium') {
		margin-top: $lsu;
	}
}

@keyframes bounce {
	0%, 20%, 50%, 80%, 100% {
		transform: translateY(0);
	}

	40% {
		transform: translateY(-30px);
	}

	60% {
		transform: translateY(-15px);
	}
}