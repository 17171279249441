﻿// MACRO.IMAGE

.m-image__lightbox-trigger {
	position: relative;

	&::after {
		@include font-icon;
		content: $iconf-enlarge;
		position: absolute;
		bottom: $bsu;
		right: $bsu;
		font-size: 24px;
		text-shadow: 0 0 4px $black;
		color: $white;
	}
}
