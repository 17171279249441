@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff2?qlc575') format('woff2'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?qlc575') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?qlc575') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?qlc575##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="iconf-"], [class*=" iconf-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.iconf-zoom-out {
  &:before {
    content: $iconf-zoom-out; 
  }
}
.iconf-zoom-in {
  &:before {
    content: $iconf-zoom-in; 
  }
}
.iconf-YEAR_WEATHERSECURITY_WARRANTY {
  &:before {
    content: $iconf-YEAR_WEATHERSECURITY_WARRANTY; 
  }
}
.iconf-EARTHQUAKE_RESISTANT {
  &:before {
    content: $iconf-EARTHQUAKE_RESISTANT; 
  }
}
.iconf-ENVIRONMENTALLY_SUSTAINABLE {
  &:before {
    content: $iconf-ENVIRONMENTALLY_SUSTAINABLE; 
  }
}
.iconf-HAIL_RESISTANT {
  &:before {
    content: $iconf-HAIL_RESISTANT; 
  }
}
.iconf-HURRICANE_RESISTANT {
  &:before {
    content: $iconf-HURRICANE_RESISTANT; 
  }
}
.iconf-LIGHTWEIGHT_INSTALLATION {
  &:before {
    content: $iconf-LIGHTWEIGHT_INSTALLATION; 
  }
}
.iconf-MARINE_ENVIRONMENT {
  &:before {
    content: $iconf-MARINE_ENVIRONMENT; 
  }
}
.iconf-NON_COMBUSTIBLE {
  &:before {
    content: $iconf-NON_COMBUSTIBLE; 
  }
}
.iconf-SNOW_FREE_RESISTANT {
  &:before {
    content: $iconf-SNOW_FREE_RESISTANT; 
  }
}
.iconf-UV_PROTECTION {
  &:before {
    content: $iconf-UV_PROTECTION; 
  }
}
.iconf-chevron-up {
  &:before {
    content: $iconf-chevron-up; 
  }
}
.iconf-chevron-right {
  &:before {
    content: $iconf-chevron-right; 
  }
}
.iconf-chevron-left {
  &:before {
    content: $iconf-chevron-left; 
  }
}
.iconf-chevron-down {
  &:before {
    content: $iconf-chevron-down; 
  }
}
.iconf-arrow-left {
  &:before {
    content: $iconf-arrow-left; 
  }
}
.iconf-arrow-right {
  &:before {
    content: $iconf-arrow-right; 
  }
}
.iconf-arrow-down {
  &:before {
    content: $iconf-arrow-down; 
  }
}
.iconf-arrow-up {
  &:before {
    content: $iconf-arrow-up; 
  }
}
.iconf-close {
  &:before {
    content: $iconf-close; 
  }
}
.iconf-tick {
  &:before {
    content: $iconf-tick; 
  }
}
.iconf-plus {
  &:before {
    content: $iconf-plus; 
  }
}
.iconf-minus {
  &:before {
    content: $iconf-minus; 
  }
}
.iconf-circle-plus {
  &:before {
    content: $iconf-circle-plus; 
  }
}
.iconf-enlarge {
  &:before {
    content: $iconf-enlarge; 
  }
}
.iconf-circle-minus {
  &:before {
    content: $iconf-circle-minus; 
  }
}
.iconf-search {
  &:before {
    content: $iconf-search; 
  }
}
.iconf-external {
  &:before {
    content: $iconf-external; 
  }
}
.iconf-download {
  &:before {
    content: $iconf-download; 
  }
}
.iconf-refresh {
  &:before {
    content: $iconf-refresh; 
  }
}
.iconf-reset {
  &:before {
    content: $iconf-reset; 
  }
}
.iconf-print {
  &:before {
    content: $iconf-print; 
  }
}
.iconf-weibo {
  &:before {
    content: $iconf-weibo; 
  }
}
.iconf-calendar {
  &:before {
    content: $iconf-calendar; 
  }
}
.iconf-info {
  &:before {
    content: $iconf-info; 
  }
}
.iconf-share {
  &:before {
    content: $iconf-share; 
  }
}
.iconf-filter {
  &:before {
    content: $iconf-filter; 
  }
}
.iconf-photo {
  &:before {
    content: $iconf-photo; 
  }
}
.iconf-mouse {
  &:before {
    content: $iconf-mouse; 
  }
}
.iconf-instagram {
  &:before {
    content: $iconf-instagram; 
  }
}
.iconf-facebook {
  &:before {
    content: $iconf-facebook; 
  }
}
.iconf-youtube {
  &:before {
    content: $iconf-youtube; 
  }
}
.iconf-twitter {
  &:before {
    content: $iconf-twitter; 
  }
}
.iconf-linkedin {
  &:before {
    content: $iconf-linkedin; 
  }
}
.iconf-pinterest {
  &:before {
    content: $iconf-pinterest; 
  }
}
.iconf-play {
  &:before {
    content: $iconf-play; 
  }
}
.iconf-email {
  &:before {
    content: $iconf-email; 
  }
}
.iconf-padlock {
  &:before {
    content: $iconf-padlock; 
  }
}
.iconf-telephone {
  &:before {
    content: $iconf-telephone; 
  }
}
.iconf-internet {
  &:before {
    content: $iconf-internet; 
  }
}
.iconf-globe {
  &:before {
    content: $iconf-globe; 
  }
}
.iconf-language {
  &:before {
    content: $iconf-language; 
  }
}
.iconf-web {
  &:before {
    content: $iconf-web; 
  }
}
.iconf-trash {
  &:before {
    content: $iconf-trash; 
  }
}
.iconf-faq {
  &:before {
    content: $iconf-faq; 
  }
}
.iconf-link {
  &:before {
    content: $iconf-link; 
  }
}
.iconf-maps-marker {
  &:before {
    content: $iconf-maps-marker; 
  }
}
.iconf-basket-add {
  &:before {
    content: $iconf-basket-add; 
  }
}
.iconf-basket-added {
  &:before {
    content: $iconf-basket-added; 
  }
}
.iconf-basket {
  &:before {
    content: $iconf-basket; 
  }
}

