// CONTROL.PATHWAYS-GERARD

.c-pathways__body {
	&::before {
		@include brand-gradient(0.8);
	}

	&:hover,
	&:focus {
		&::before {
			@include pathway-bg-transform(0);
			opacity: 1;
			@include bp("large") {
				@include pathway-bg-transform(0);
			}
		}
	}
}

.c-pathways__title {
	@include font-40hu;
	margin-top: 0em;
}

.c-pathways__abstract {
	max-width: none;
}
