$icomoon-font-family: "font-icons" !default;
$icomoon-font-path: "fonts" !default;

$iconf-zoom-out: "\e904";
$iconf-zoom-in: "\e905";
$iconf-YEAR_WEATHERSECURITY_WARRANTY: "\e906";
$iconf-EARTHQUAKE_RESISTANT: "\e907";
$iconf-ENVIRONMENTALLY_SUSTAINABLE: "\e908";
$iconf-HAIL_RESISTANT: "\e909";
$iconf-HURRICANE_RESISTANT: "\e90a";
$iconf-LIGHTWEIGHT_INSTALLATION: "\e90d";
$iconf-MARINE_ENVIRONMENT: "\e90e";
$iconf-NON_COMBUSTIBLE: "\e910";
$iconf-SNOW_FREE_RESISTANT: "\e912";
$iconf-UV_PROTECTION: "\e913";
$iconf-chevron-up: "\e900";
$iconf-chevron-right: "\e901";
$iconf-chevron-left: "\e902";
$iconf-chevron-down: "\e903";
$iconf-arrow-left: "\2190";
$iconf-arrow-right: "\2192";
$iconf-arrow-down: "\2193";
$iconf-arrow-up: "\2191";
$iconf-close: "\274c";
$iconf-tick: "\2713";
$iconf-plus: "\2b";
$iconf-minus: "\2d";
$iconf-circle-plus: "\2295";
$iconf-enlarge: "\2295";
$iconf-circle-minus: "\2296";
$iconf-search: "\1f50d";
$iconf-external: "\e90b";
$iconf-download: "\e90c";
$iconf-refresh: "\21c4";
$iconf-reset: "\21c4";
$iconf-print: "\2399";
$iconf-weibo: "\e90f";
$iconf-calendar: "\1f4c5";
$iconf-info: "\24d8";
$iconf-share: "\e914";
$iconf-filter: "\e915";
$iconf-photo: "\1f4f7";
$iconf-mouse: "\1f5b1";
$iconf-instagram: "\e918";
$iconf-facebook: "\e922";
$iconf-youtube: "\e91a";
$iconf-twitter: "\e91c";
$iconf-linkedin: "\e924";
$iconf-pinterest: "\e911";
$iconf-play: "\25b6";
$iconf-email: "\2709";
$iconf-padlock: "\1f512";
$iconf-telephone: "\260e";
$iconf-internet: "\1f310";
$iconf-globe: "\1f310";
$iconf-language: "\1f310";
$iconf-web: "\1f310";
$iconf-trash: "\1f5d1";
$iconf-faq: "\e923";
$iconf-link: "\1f517";
$iconf-maps-marker: "\1f4cc";
$iconf-basket-add: "\e926";
$iconf-basket-added: "\e927";
$iconf-basket: "\e928";

