// CONTROL.RESOURCES

.c-resources {}

.c-resources__list {
	@include list-reset;

	@include flex-extend;
	align-items: stretch;
	flex-wrap: wrap;

	margin-left: -$bsu;
	margin-top: -$bsu;
}

.c-resources__list-actions {
	@include flex-extend;
	align-items: flex-end;
	justify-content: flex-end;
}

.c-resources__list-action {
	+ .c-resources__list-action {
		margin-left: $ssu;
	}
}

.c-resources__list-actions,
.c-resources__item-layout {
	padding-left: $bsu;
	padding-top: $bsu;

	width: 100%;
	@include bp("large") {
		width: 50%;
	}
}

.o-layout-content--has-aside {
	.c-resources__list-actions,
	.c-resources__item-layout {
		width: 100%;
	}
}

.c-resources__list-actions:nth-child(2n+1) {
	width: 100%;
}

.c-resources__item {
	@include flex-extend;
	flex-align: flex-start;

	flex-direction: column;
	@include bp("medium") {
		flex-direction: row;
	}
}

.c-resources__item--featured {
	position: relative;

	&::before,
	&::after {
		@include pseudo-element;
		top: 0;
	}

	&::before {
		left: 0;
		z-index: 1;
		border-style: solid;
		border-width: 17px 25px;
		border-color: rgba($brand, 0.5) transparent transparent rgba($brand, 0.5);
	}

	&::after {
		right: -4px;
		width: 4px;
		height: 100%;
		background-color: $brand;
	}
}

.c-resources__figure {
	display: block;

	@include bp("medium") {
		width: 150px;
	}
}

.c-resources__image {
	display: block;
	width: 100%;

	@include bp("medium") {
		width: 150px;
	}
}

.c-resources__body {
	flex-grow: 1;
	@include flex-extend;
	flex-direction: column;

	@include bp("medium") {
		flex-direction: row;
	}

	background: $grey--lightest;
}

.c-resources__details {
	flex-grow: 1;
	padding: $ssu;

	@include flex-extend;
	flex-direction: column;
	justify-content: space-between;
}

.c-resources__title {
	@include font-16b;
	margin-bottom: $tsu;
}

.c-resources__type-list {
	@include list-reset;
	@include flex-extend;
	flex-wrap: wrap;

	margin-left: -15px;
}

.c-resources__type {
	padding-left: 15px;
	padding-bottom: $ssu;
	@include font-14;

	@include flex-extend;
	align-items: center;
}

.c-resources__type-label {
	@include flex-extend;
	align-items: center;
	cursor: pointer;

	.c-fake-checkbox__display {
		margin-right: $tsu;
	}
}

.c-resources__type-icon {
	display: block;
	width: 20px;
	margin-right: $tsu;
}

.c-resources__type-text {
	@include font-14b;
}


.c-resources__actions {
	@include flex-extend;

	@include bp-below("medium") {
		flex-direction: column;
		align-items: stretch;
	}
}

.c-resources__button {
	@include button-reset;

	display: block;
	height: 100%;
	padding: $ssu $bsu;

	@include flex-extend;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	border-left: 1px solid $white;
	@include bp-below("medium") {
		border-left: none;
	}

	&::before {
		@include font-icon;

		font-size: 24px;
		margin-bottom: $tsu;
	}

	@include font-14;

	color: $brand;
	&:hover,
	&:focus {
		background: $brand;
		color: $white;
	}

	&:disabled {
		&,
		&:hover,
		&:focus {
			color: $grey;
			background: transparent;
			cursor: not-allowed;
		}
	}

	@include bp-below("medium") {
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;

		border-left: 0;
		border-top: 2px solid $white;
		&::before {
			margin-right: $ssu;
			margin-bottom: 0;
		}
	}
}

.c-resources__button--download {
	&::before {
		content: $iconf-download;
	}
}

.c-resources__button-text-options {
	@include flex-extend;
	flex-direction: column;
	align-items: flex-start;
}

.c-resources__button-text {
	display: block;
	height: 21px;

	@include bp("medium") {
		margin: 0 auto;
	}
	white-space: nowrap;
	line-height: 21px;
	@include font-14b;
}

.c-resources__button-text--add { visibility: visible; }
.c-resources__button-text--added { visibility: hidden; }
.c-resources__button-text--remove { visibility: hidden; }

.c-resources__button-text--added,
.c-resources__button-text--remove {
	margin-top: -21px;
}

.c-resources__button--basket {
	&::before {
		content: $iconf-basket-add;
	}
	&[aria-pressed="true"] {
		.c-resources__button-text--add { visibility: hidden; }
		.c-resources__button-text--added { visibility: visible; }
		&::before {
			content: $iconf-basket-added;
		}

		color: $brand--alt;
		&:hover,
		&:focus {
			color: $white;

			.c-resources__button-text--added { visibility: hidden; }
			.c-resources__button-text--remove { visibility: visible; }
		}
	}
}
