// COMPONENT.HERO-GERARD

.c-hero__layout {
	max-width: none;
	padding: 0;

	.o-layout-page & {
		margin-top: -$bsu;

		@include bp("medium") {
			margin-top: -$lsu;
		}

		@include bp(1440px) {
			margin-top: -$bsu;
		}

		@include bp(1600px) {
			margin-top: 0;
		}
	}
}

.c-hero__secondary {
	padding: 0 20px;
}

.c-hero__body {
	position: absolute;
	z-index: 1;
	color: $white;
	width: 100%;
	height: 90%;
	bottom: 0;
	padding: 0;

	@include bp($hero-bp) {
		height: 80%;
	}
}

.c-hero__title,
.c-hero__text {
	width: 100%;
	@include layout-wrapper;
}

.c-hero__title {
	flex-grow: 0;
	margin-bottom: $lsu;

	color: $white;
	@include font-70hn;
	text-shadow: 2px 2px 8px rgba($black, 0.8);
}

.c-hero__text {
	text-shadow: 2px 2px 8px rgba($black, 0.8);
}

.c-hero__title-inner,
.c-hero__text-inner {
	display: block;
	max-width: 830px;
}

.c-hero__secondary {
	@include layout-wrapper;

	margin-top: $lsu;
	margin-bottom: $lsu;
	@include bp("large") {
		margin-top: $hsu;
		margin-bottom: $hsu;
	}
}

.c-hero__secondary-title-text {
	@include font-50hn;
	padding-left: 0;
}


///////////////
//   GERARD  //
///////////////
.c-hero__primary--gerard {
	display: block;
}

.c-hero__gerard-wrap {
	position: relative;
	overflow: hidden;

	h2, p {
		text-shadow: none;
	}

	h2 {
		margin: 0;
		font-size: 28px;

		@include bp("small") {
			font-size: 50px;
		}
	}

	p {
		margin-top: 40px;
		font-size: 16px;
		font-weight: 400;
		color: white;
		text-shadow: none;
	}

	.c-hero__text {
		display: block;
	}
}

.c-hero__gerard-image {

	@include bp(1200px) {
		height: 630px;
	}

	.c-hero__image {
		padding-bottom: 0;

		img {
			display: block;
			position: relative;
			width: 100%;
			height: calc(100vh - 150px);
			object-fit: cover;

			@include bp(1200px) {
				height: 100%;
			}
		}
	}
}

.c-hero__panel-wrapper {
	@include layout-wrapper;
	position: absolute;
	left: 50%;
	top: 0;
	width: 100%;
	height: 100%;
	transform: translateX(-50%);
	box-sizing: border-box;
}


.c-hero__gradient-background,
.c-hero__thick-border {
	position: absolute;
}

.c-hero__gradient-background {
	bottom: 60px;
	width: calc(100% - 40px);
	max-width: 550px;
	padding: 25px;
	background-image: linear-gradient(90deg, rgba(0,66,117,0.87) 20%, rgba(39,189,190,0.87) 100%);
	box-sizing: border-box;

	@include bp("small") {
		top: 50%;
		bottom: auto;
		transform: translateY(-50%);
		width: 68%;
		padding: 40px;
	}

	.c-hero__title,
	.c-hero__text {
		padding: 0;
	}

	.c-hero__text {
		margin-top: 16px;
		@include bp("small") {
			margin-top: 35px;
		}
		p {
			margin-top: 0;
			&:last-child {
				margin: 0;
			}
		}
	}
}

.c-hero__thick-border {
	top: auto;
	left: 30px;
	bottom: 40px;
	padding: 25px 17px;
	width: calc(100% - 60px);
	background-color: rgba(0,0,0,0.2);
	border: 8px solid #ffffff;

	@include bp(1025px) {
		top: 90px;
		left: 90px;
		bottom: auto;
		padding: 30px 27px;
		width: 80%;
		max-width: 600px;
		background-color: rgba(0,0,0,0);
		border-width: 16px;
	}

	h2.c-hero__title {
		padding-bottom: 30px;
		font-size: 24px;
		font-weight: bold;

		@include bp(1025px) {
			font-size: 46px;
		}
	}

	.c-hero__text-inner {
		padding-top: 20px;
		border-top: 1px solid #ffffff;

		p {
			display: inline;
			font-size: 14px;
			font-weight: bold;
			line-height: 1.75;

			@include bp(1025px) {
				font-size: 26px;
				line-height: 1.4;
			}
		}

		a.icon-link {
			margin-left: 10px;
			padding: 3px 4px;
			color: #222;
			background-color: #ffffff;

			span {
				position: relative;
				top: 2px;
				font-size: 20px;
			}
		}
	}
}

.c-hero__thick-border--blue {
	border-color: $brand;
	background-color: rgba($white, 0.2);

	@include bp(1025px) {
		background-color: transparent;
	}

	.c-hero__title {
		color: $brand;
	}

	.c-hero__text-inner {
		border-top-color: $brand;

		p {
			color: $brand;
		}

		a.icon-link {
			color: $white;
			background-color: $brand;
		}
	}
}

.c-hero.swiper {
	.c-hero__primary {
		margin-bottom: 0;
	}
}

.swiper-pagination-bullets.swiper-pagination-horizontal {
	position: absolute;
	top: auto;
	bottom: 20px;
	left: 0;
	width: 100%;
	display: flex;
	justify-content: center;
	z-index: 10;
}

.swiper-pagination-bullet-active {
	background-color: #ffffff;
}