// CONTROL.POD-LISTING


.c-pod-listing {
	display: block;
}

.c-pod-listing__head {
	margin-bottom: $bsu;
}

.c-pod-listing__image {}

.c-pod-listing__body {
	padding: 0 5px;
}

.c-pod-listing__heading {
	display: block;

	color: $brand;
	@include font-18b;
}

.c-pod-listing__subtitle {
	margin-top: $tsu;
	display: block;
	margin-bottom: $bsu;

	@include font-12;
	color: $grey--dark;
}

.c-pod-listing__text {
	display: block;
	margin-top: $ssu;
	margin-bottom: 0;

	color: $body-color;
	@include font-14;
}

a.c-pod-listing {
	.c-pod-listing__head {
		position: relative;
		overflow: hidden;

		&::before,
		&::after {
			@include link-transition;
		}
		&::before {
			@include font-icon;
			content: $iconf-arrow-right;

			position: absolute;
			z-index: 1;
			right: 0;
			bottom: 0;
			color: $brand;
			font-size: 24px;

			transform: rotate(35deg) translate(60px, 0) rotate(-35deg);
		}
		&::after {
			content: "";
			position: absolute;
			bottom: 0;
			right: 0;

			display: block;
			width: 0;
			height: 0;

			border-bottom: 0 solid $white;
			border-top: 0 solid transparent;
			border-right: 0 solid $white;
			border-left: 0 solid transparent;
		}
	}
	&:hover,
	&:focus {
		.c-pod-listing__head {
			&::before {
				opacity: 1;

				transform: translate(0, 0);
			}
			&::after {
				border-bottom-width: 27px;
				border-top-width: 27px;
				border-right-width: #{27px * $brand-angle-multiplier};
				border-left-width: #{27px * $brand-angle-multiplier};
			}
		}
	}

	&[href^="http"] {
		.c-pod-listing__head {
			&::before {
				content: $iconf-external;

				opacity: 1;
				transform: none;

				bottom: $ssu;
				right: $ssu;

				width: 40px;
				height: 40px;
				font-size: 20px;
				border-radius: 100%;

				@include flex-extend;
				align-items: center;
				justify-content: center;

				background: $brand;
				color: $white;
			}
			&::after {
				display: none;
			}
		}
		&:hover,
		&:focus {
			.c-pod-listing__head {
				&::before {
					transform: none;
					background: $brand--alt;
				}
			}
		}
	}
}

.c-product__section--grey {
	a.c-pod-listing {
		.c-pod-listing__head {
			&::after {
				border-bottom-color: $grey--lightest;
				border-right-color: $grey--lightest;
			}
		}
	}
}
