/* PLUGINS.MAGNIFIC-POPUP */

$mfp-overlay-color: #000;
$mfp-overlay-opacity: 0.6; // 0.0 - 1.0
$mfp-shadow: 0 0 8px rgba(0, 0, 0, 0.6); // shadow on image or iframe
$mfp-popup-padding-left: 8px; // Padding from left and from right side
$mfp-popup-padding-left-mobile: 5px; // Same as above, but is applied when width of window is less than 800px

$mfp-z-index-base: 1040; // Base z-index of popup
$mfp-include-arrows: true; // include styles for nav arrows
$mfp-controls-opacity: 0.65;
$mfp-controls-color: #fff;
$mfp-controls-border-color: #fff;
$mfp-inner-close-icon-color: #fff;
$mfp-controls-text-color: #fff; // Color of preloader and "1 of X" indicator
$mfp-controls-text-color-hover: #fff;

// Iframe-type options
$mfp-include-iframe-type: true;
$mfp-iframe-padding-top: 40px;
$mfp-iframe-background: #000;
$mfp-iframe-max-width: 900px;
$mfp-iframe-ratio: 3/4;

// Image-type options
$mfp-include-image-type: true;
$mfp-image-background: #fff;
$mfp-image-padding-top: 40px;
$mfp-image-padding-bottom: 40px;
$mfp-include-mobile-layout-for-image: true; // Removes paddings from top and bottom

// Image caption options
$mfp-caption-title-color: #fff;
$mfp-caption-subtitle-color: #fff;
$mfp-controls-bg: none;

// A11y
$mfp-use-visuallyhidden: false; // Hide content from browsers, but make it available for screen readers

// Transluscent overlay
.mfp-bg {
	position: fixed;
	top: 0;
	left: 0;
	z-index: $mfp-z-index-base + 2;
	overflow: hidden;
	opacity: $mfp-overlay-opacity;
	width: 100%;
	height: 100%;
	background: $mfp-overlay-color;
}

// Wrapper for popup
.mfp-wrap {
	position: fixed;
	top: 0;
	left: 0;
	z-index: $mfp-z-index-base + 3;
	width: 100%;
	height: 100%;
	outline: none !important;
	-webkit-backface-visibility: hidden; // Fixes webkit bug that can cause "false" scrollbar
}

// Root container
.mfp-container {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	padding: 0 $mfp-popup-padding-left;
	text-align: center;
}

// Vertical centerer helper
.mfp-container {
	&:before {
		content: '';
		display: inline-block;
		height: 100%;
		vertical-align: middle;
	}
}

// Remove vertical centering when popup has class `mfp-align-top`
.mfp-align-top {
	.mfp-container {
		&:before {
			display: none;
		}
	}
}

// Popup content holder
.mfp-content {
	position: relative;
	z-index: $mfp-z-index-base + 5;
	display: inline-block;
	margin: 0 auto;
	text-align: left;
	vertical-align: middle;
}

.mfp-inline-holder,
.mfp-ajax-holder {
	.mfp-content {
		width: 100%;
		cursor: auto;
	}
}

// Cursors
.mfp-ajax-cur {
	cursor: progress;
}

.mfp-zoom {
	cursor: pointer;
	cursor: -webkit-zoom-in;
	cursor: -moz-zoom-in;
	cursor: zoom-in;
}

.mfp-auto-cursor {
	.mfp-content {
		cursor: auto;
	}
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
	user-select: none;
}

// Hide the image during the loading
.mfp-loading {
	&.mfp-figure {
		display: none;
	}
}

// begin if
// Helper class that hides stuff
@if $mfp-use-visuallyhidden {

	// From HTML5 Boilerplate https://github.com/h5bp/html5-boilerplate/blob/v4.2.0/doc/css.md#visuallyhidden
	.mfp-hide {
		position: absolute !important;
		clip: rect(0 0 0 0) !important;
		overflow: hidden !important;
		width: 1px !important;
		height: 1px !important;
		padding: 0 !important;
		border: 0 !important;
		margin: -1px !important;
	}

} @else {

	.mfp-hide {
		display: none !important;
	}
}
// end if

// Preloader and text that displays error messages
.mfp-preloader {
	position: absolute;
	left: 8px;
	right: 8px;
	top: 50%;
	z-index: $mfp-z-index-base + 4;
	margin-top: -0.8em;
	width: auto;
	color: $mfp-controls-text-color;
	text-align: center;

	a {
		color: $mfp-controls-text-color;

		&:hover {
			color: $mfp-controls-text-color-hover;
		}
	}
}

// Hide preloader when content successfully loaded
.mfp-s-ready {
	.mfp-preloader {
		display: none;
	}
}

// Hide content when it was not loaded
.mfp-s-error {
	.mfp-content {
		display: none;
	}
}

// CSS-reset for buttons
.mfp-close,
.mfp-arrow {
	z-index: $mfp-z-index-base + 6;
	display: block;
	overflow: visible;
	padding: 0;
	border: 0;
	outline: none;
	box-shadow: none;
	cursor: pointer;
	background: transparent;
	-webkit-appearance: none;
}

button {
	&::-moz-focus-inner {
		padding: 0;
		border: 0
	}
}


// Close icon
.mfp-close {
	position: absolute;
	right: 0;
	top: 0;
	opacity: $mfp-controls-opacity;
	width: 50px;
	height: 50px;
	padding: 0;
	border: 1px solid $mfp-controls-border-color;
	line-height: 48px;
	font-style: normal;
	font-size: 28px;
	font-family: Arial, Baskerville, monospace;
	text-decoration: none;
	text-align: center;
	color: $mfp-controls-color;
	background: $mfp-controls-bg;

	&:hover{
		//background-color: $link--hover-color;
	}

	.mfp-image-holder & {
		top: $mfp-image-padding-top;
	}
}

.mfp-close-btn-in {
	.mfp-close {
		color: $mfp-inner-close-icon-color;

		&:hover{
			color: $white;
		}
	}
}

// "1 of X" counter
.mfp-counter {
	position: absolute;
	right: 0;
	top: 0;
	display: inline-block;
	font-weight: bold;
	font-style: italic;
	font-size: 18px;
	line-height: 20px;
	color: $mfp-controls-text-color;
}

// begin if
// Navigation arrows
@if $mfp-include-arrows {

	.mfp-arrow {
		position: absolute;
		top: 50%;
		opacity: 0.5;
		width: 60px;
		height: 136px;
		margin-top: -68px;
		-webkit-filter: drop-shadow(1px 1px 4px rgba(0,0,0,0.6));

		&:before {
			position: absolute;
			left: -35px;
			top: 50%;
			display: block;
			margin-top: -60px;
			font-size: 120px;
			color: $mfp-controls-color;

			.csstransforms & {
				content: '';
				position: absolute;
				left: -40px;
				top: 50%;
				height: 85px;
				width: 90px;
				border-top: 5px solid $white;
				border-right: 5px solid $white;
				margin-top: -40px;
				transform: rotate(53deg) skew(15deg );
			}
		}

		&:hover {
			opacity: 1;

			&:before {
				//color: $link-color;
				//border-color: $link-color;
			}
		}
	}

	.mfp-arrow-left {
		left: $bsu;

		&:before {
			.csstransforms & {
				left: 10px;
				transform: rotate(53deg - 180deg) skew(15deg );
			}
		}
	}

	.mfp-arrow-right {
		right: $bsu;
	}
}
// end if


// begin if
// Iframe content type
@if $mfp-include-iframe-type {

	.mfp-iframe-holder {
		padding-top: $mfp-iframe-padding-top;
		padding-bottom: $mfp-iframe-padding-top;

		.mfp-content {
			width: 100%;
			max-width: $mfp-iframe-max-width;
			line-height: 0;
		}

		.mfp-close {
			top: -50px;
		}
	}

	.mfp-iframe-scaler {
		overflow: hidden;
		width: 100%;
		height: 0;
		padding-top: $mfp-iframe-ratio * 100%;

		iframe {
			position: absolute;
			display: block;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			box-shadow: $mfp-shadow;
			background: $mfp-iframe-background;
		}
	}
}
// end if

// begin if
// Image content type
@if $mfp-include-image-type {

	// Main image in popup
	img {
		&.mfp-img {
			display: block;
			width: auto;
			max-width: 100%;
			height: auto;
			padding: $mfp-image-padding-top 0 $mfp-image-padding-bottom;
			margin: 0 auto;
			line-height: 0;
		}
	}

	// The shadow behind the image */
	.mfp-figure {
		line-height: 0;

		&:after {
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			top: $mfp-image-padding-top;
			bottom: $mfp-image-padding-bottom;
			z-index: -1;
			display: block;
			width: auto;
			height: auto;
			box-shadow: $mfp-shadow;
			background: $mfp-image-background;
		}

		small {
			display: block;
			font-size: 12px;
			line-height: 14px;
			color: $mfp-caption-subtitle-color;
		}

		figure {
			margin: 0;
		}
	}

	.mfp-bottom-bar {
		position: absolute;
		left: 0;
		top: 100%;
		width: 100%;
		margin-top: -$mfp-image-padding-bottom + 4;
		cursor: auto;
		text-align: center;
	}

	.mfp-title {
		display: inline-block;
		font-size: 18px;
		font-style: italic;
		line-height: 20px;
		word-wrap: break-word;
		color: $mfp-caption-title-color;
		padding: 0 80px; // To allow space for gallery number
	}

	.mfp-image-holder {
		.mfp-content {
			max-width: 100%;
		}
	}

	.mfp-gallery {
		.mfp-image-holder {
			.mfp-figure {
				cursor: pointer;
			}
		}
	}

	// begin if
	@if $mfp-include-mobile-layout-for-image {
		@media screen and (max-width: 800px) and (orientation:landscape), screen and (max-height: 300px) {

			// Remove all paddings around the image on small screen
			.mfp-img-mobile {
				.mfp-image-holder {
					padding-right: 0;
					padding-left: 0;
				}

				img {
					&.mfp-img {
						padding: 0;
					}
				}

				.mfp-figure {

					// The shadow behind the image
					&:after {
						top: 0;
						bottom: 0;
					}

					small {
						display: inline;
						margin-left: 5px;
					}
				}

				.mfp-bottom-bar {
					position: fixed;
					top: auto;
					bottom: 0;
					padding: 3px 5px;
					margin: 0;
					background: rgba(0,0,0,0.6);

					&:empty {
						padding: 0;
					}
				}

				.mfp-counter {
					right: 5px;
					top: 3px;
				}

				.mfp-close {
					position: fixed;
					right: 0;
					top: 0;
					width: 35px;
					height: 35px;
					padding: 0;
					line-height: 35px;
					text-align: center;
					background: rgba(0, 0, 0, 0.6);
				}
			}
		}
	}
	// end if
}
// end if


// Scale navigation arrows and reduce padding from sides
@media all and (max-width: 900px) {

	.mfp-arrow {
		transform: scale(0.75);
	}

	.mfp-arrow-left {
		transform-origin: 0;
	}

	.mfp-arrow-right {
		transform-origin: 100%;
	}

	.mfp-container {
		padding-left: $mfp-popup-padding-left-mobile;
		padding-right: $mfp-popup-padding-left-mobile;
	}
}