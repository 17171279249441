// COMPONENT.COOKIE-CONFIRM

.c-cookie-confirm {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
	display: none;
	background: rgba($grey--dark, 0.8);
}

.c-cookie-confirm__body {
	padding: $tsu $bsu $tsu 0;
	color: $white;

	a {
		color: $white;
		text-decoration: underline;
	}

	@include bp('medium') {
		display: flex;
		align-items: center;
	}

	@include bp("large") {
		position: relative;
		padding-right: $lsu;
	}
}

.c-cookie-confirm__message {
	margin-bottom: $ssu;

	@include bp('medium') {
		flex: 1 1 auto;
		margin: 0;
	}
}

.c-cookie-confirm__button {
	flex: 0 1 auto;

	@include bp('medium') {
		margin-left: $ssu;
	}
}

.c-cookie-confirm__close {
	cursor: pointer;

	@include font-icon;
	position: absolute;
	top: 0;
	right: 0;
	padding: $ssu;
	background: none;
	border: 0;

	&::before {
		content: $iconf-close;
	}
}
