﻿// CONTROL.VIDEO-GROUP

.c-video-group {

    &__heading {
        margin-bottom: 40px;
    }

    &__cover {
        @include abs-center;
        width: 100%;
    }


    &__item-heading {
        @include font-18b;
        font-family: $base-font-family;
        margin-top: 15px;
        color: $brand;
        transition: 0.3s ease-in all;
    }

    &__item {
        display: block;
        position: relative;
        overflow: hidden;

        &:hover,
        &:focus {

            .c-video-group__item-heading {
                color: $brand--alt;
            }
        }
    }

    &__thumbnail {
        padding-bottom: percentage(9/16);

        img {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
        }
    }


    &__item-icon {
        position: absolute;
        bottom: $bsu;
        right: $bsu;
        background: $brand;
        @include circle(50px);
        box-shadow: 0 0 5px 5px rgba($black, 0.15);
        color: $white;
        font-size: 30px;
        pointer-events: none;
    }
}

.c-video-group__overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba($black, 0.5);
    overflow: hidden;
    visibility: hidden;
    height: 0;


    .is_open & {
        position: fixed;
        z-index: 1000;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba($black, 0.8);
        overflow: inherit;
        visibility: visible;
    }
}

.c-video-group__overlay-content {
    width: 100%;
    max-width: 900px;
    position: relative;
    z-index: 1045;
    display: inline-block;
    margin: 0 auto;
    text-align: left;
    vertical-align: middle;
}

.c-video-group__iframe-wrap {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 0;
    padding-bottom: 75%;
}

.c-video-group__iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
}


@include bp(large) {
    .c-video-group {
        & + .c-video-group {
            margin-top: 80px;
        }
    }
}
