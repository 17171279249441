﻿// OBJECT.DROPDOWN

.o-dropdown {
	position: relative;
}

.o-dropdown__selected {
	@include flex-extend;
	align-items: center;
	position: relative;
	height: 100%;
	padding: $tsu $lsu $tsu $bsu;
	cursor: pointer;

	&::after {
		@include pseudo-element;
		right: $bsu;
		top: 0;
		border-width: 8px 5px 8px 5px;
		border-color: $black transparent transparent;
		border-style: solid;
		top: 50%;
		transform: translateY(-4px);
	}
}

.o-dropdown__options {
	display: none;
	width: 100%;
	position: absolute;
	left: 0;
	padding-bottom: $bsu;
	background-color: $white;

	.o-dropdown.is-expanded &,
	.o-dropdown[aria-expanded="true"] & {
		display: block;
	}
}

.o-dropdown__heading {
	@include font-10b;
	display: block;
	padding: ($ssu + $tsu) $bsu $tsu;
}

.o-dropdown__option {
	@include flex-extend;
	align-items: center;
	padding: $tsu $bsu;

	&:focus,
	&:hover {
		background-color: $grey--lightest;
	}

	&:last-child {
		padding-bottom: 0;
	}
}

.o-dropdown__option-img {
	margin-right: $ssu;

	.o-dropdown__selected & {
		width: 30px;
		height: 30px;
	}
}

.o-dropdown__option-text {
	@include font-14b;
	color: $grey--darkest;

	.o-dropdown__selected & {
		@include font-16b;
	}
}

.o-dropdown__cta {
	display: block;
	width: calc(100% - #{$bsu * 2});
	margin: $bsu auto 0;
}
