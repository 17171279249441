// COMPONENT.BASKET

.c-basket {
	// So loader covers badge
	margin-top: -$ssu;
	padding-top: $ssu;
}



.c-basket__head {
	background: $black;
	color: $white;
	padding: $ssu $ssu $ssu $bsu;

	position: relative;
}

.c-basket__title {
	@include flex-extend;
	align-items: center;

	padding-right: 32px + $bsu;

	@include font-14h;

	&::before {
		@include font-icon;
		content: $iconf-basket;

		margin-right: $ssu;
	}
}

.c-basket__badge {
	position: absolute;
	top: -$ssu;
	right: $ssu;

	@include circle(32px);
	@include font-14b;

	@include flex-extend;
	align-items: center;
	justify-content: center;

	background: $brand;
}



.c-basket__body {
	background: $grey--lightest;

	// Prevent margin overflow
	overflow: hidden;

	.c-loader {
		padding-top: 50%;
		&::before {
			background: none;
		}
	}
}


.c-basket__list {
	@include list-reset;
	padding: $ssu 0;
}

.c-basket__item {
	width: 100%;

	&:nth-child(n+6) {
		display: none;
	}
}

.c-basket__item--show-all {
	display: none;
	&:nth-child(n+7) {
		display: block;
	}
}

.c-basket__remove {
	@include button-reset;
	@include font-12;

	color: $black;
	&:hover,
	&:focus {
		&,
		&::after {
			color: $brand;
		}
	}

	@include flex-extend;
	width: 100%;
	align-items: flex-start;
	justify-content: space-between;
	text-align: left;

	padding: $ssu $bsu;

	&::after {
		@include font-icon;
		content: $iconf-close;
		font-size: 0.8em;

		margin-left: $ssu;

		color: $grey--light;
	}
}


.c-basket__show-all {
	@include button-reset;
	text-align: left;
	@include font-14;

	margin: $ssu $bsu $bsu;

	@include link-transition;
	color: $brand;
	&:hover,
	&:focus {
		color: $brand--alt;
	}

	@include flex-extend;
	align-items: center;
	&::before {
		@include font-icon;
		content: $iconf-plus;

		margin-right: $tsu;
	}
}

.c-basket__list[aria-expanded="true"] {
	.c-basket__item {
		display: block;
	}

	.c-basket__item--show-all {
		display: none;
	}
}


.c-basket__summary {
	margin: 0 $bsu $bsu;

	&:first-child {
		margin-top: $bsu;
	}
}

.c-basket__summary-number {
	@include font-18b;
}

.c-basket__summary-text {
	@include font-16b;
}

.c-basket__summary-note {
	display: block;
	margin-top: $ssu;

	@include font-14;
}


.c-basket__download {
	@include flex-extend;
	align-items: center;
	margin: 0 $bsu $bsu;

	&::before {
		@include font-icon;
		content: $iconf-download;

		margin-right: $tsu;
	}
}