﻿// CONTROL.IMAGE-CTA

.c-image-cta {
	position: relative;
}

.c-image-cta__body {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 100%;
	padding: 0 $bsu;
	max-width: $layout-wrapper-max-width;
	transform: translate(-50%, -50%);
}

.c-image-cta__heading {
	@include font-30h;
	position: relative;
	z-index: 2;
	margin-bottom: $ssu;
	color: $white;
	text-shadow: 2px 2px 8px rgba($black, 0.8);

	@include bp("medium") {
		@include font-70h;
		margin-bottom: $bsu;
	}

	@include bp("extra-large") {
		@include font-100h-only;
		margin-bottom: $lsu;
	}
}

.c-image-cta__abstract {
	@include font-16;
	margin-top: $bsu;
	color: $white;
	text-shadow: 2px 2px 8px rgba($black, 0.8);

	@include bp("large") {
		@include font-18;
	}
}

.c-image-cta__btn {
	@include font-14b;
	padding: 10px 15px;
	margin-top: $bsu;

	@include bp('medium') {
		padding: 12px 18px;
	}

	@include bp('large') {
		@include font-16b;
		padding: 18px 27px;
	}
}

// In main column with sidebar
.o-layout-content--has-aside {

	.c-image-cta__heading {

		@include bp("medium") {
			@include font-70h;
			margin-bottom: $bsu;
		}

		@include bp("large") {
			@include font-30h;
		}

		@include bp("extra-large") {
			@include font-70h;
		}
	}
}