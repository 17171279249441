﻿// COMPONENT.SEARCH-RESULT

.c-search {
	margin-bottom: $lsu;
	@include bp("large") {
		margin-bottom: $hsu;
	}
}

.c-search-results {
	margin-top: $lsu;
}

.c-search-result {
	@include flex-extend;
	align-items: flex-start;

	margin-bottom: $bsu;
	&:last-child {
		margin-bottom: 0;
	}
}

.c-search-result__media {
	width: 200px;
	max-width: percentage(1/3);
	flex-shrink: 0;
	margin-right: $bsu;
}

.c-search-result__body {}

.c-search-result__heading {
	@include font-24b;
	display: block;
	margin-bottom: $ssu;
	color: $body-color;
}

.c-search-result__heading-link {
	@include link-brand;
}

.c-search-result__url {
	@include font-12;
	display: block;
	@include link-brand;
	margin: $ssu 0;
	word-break: break-word;
}

.c-search-result__meta {
	@include font-12;
	display: block;
	margin: $ssu 0;
	color: $body-color;
}

.c-search-result__category {
	display: inline-block;
	padding: 0.5em 1em;
	margin-left: $ssu;
	color: $white;
	background-color: $brand;
}

.c-search-result__text {
	@include font-16;
	display: block;
	color: $body-color;

	@include bp('large') {
		@include font-18;
	}

	&[draggable="false"] {
		display: inline;
	}
}