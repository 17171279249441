.c-logo-embed {}

.c-logo-embed__code-list {
	@include list-reset;
	@include flex-extend;
	flex-wrap: wrap;

	width: 100%;

	margin-top: -$lsu;
	@include bp("medium") {
		margin-left: -$lsu;
	}
}

.c-logo-embed__code-item {
	padding-top: $lsu;

	@include flex-extend;
	flex-direction: column;
	align-items: center;

	width: 100%;
	@include bp("medium") {
		width: 50%;
		padding-left: $lsu;
	}
}

.c-logo-embed__example-layout {
	@include flex-extend;
	align-items: center;
	justify-content: center;

	margin-bottom: $bsu;
}

.c-logo-embed__example {
	display: inline-block;
	padding: $bsu;
	box-shadow: inset 0 0 0 1px $grey;
}

.c-logo-embed__example-copy {
	margin: $bsu auto 0;
}

.c-logo-embed__source {
	width: 100%;
	min-height: 150px;
}
