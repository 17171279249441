// Modal

$people-modal-bp: 650px;

.c-feature-modal {
	@include flex-extend;
	justify-content: flex-end;

	@include bp-below($people-modal-bp) {
		flex-wrap: wrap;
	}

	@include bp($people-modal-bp) {
		height: 100%;
	}
}

.c-feature-modal__body {
	@include bp($people-modal-bp) {
		width: percentage(2 / 3);
		max-height: 100%;
		overflow: auto;
	}

	@include bp("extra-large") {
		width: 40vw;
	}

	position: relative;
	padding: $hsu $lsu $hsu;

	&:focus {
		// Only receives focus when modal is opened on on click, shouldn't need to be indicated
		outline: none;
	}
}

.c-feature-modal__close {
	@include button-reset;
	position: absolute;
	top: $ssu;
	right: $lsu;

	@include flex-extend;
	align-items: center;
	padding: $ssu;

	@include font-14b;
	@include link-brand;
	@include link-transition;
}

.c-feature-modal__close-icon {
	margin-left: $ssu;
}

.c-feature-modal__title {
	display: block;

	@include font-24b;

	+ .c-feature-modal__subtitle {
		margin-top: $ssu;
	}
}

.c-feature-modal__subtitle {
	display: block;

	@include font-14b;
	color: $brand;

	+ .c-feature-modal__title {
		margin-top: $ssu;
	}
}

.c-feature-modal__text {
	display: block;
	margin-top: $bsu;

	a {
		@include link-brand;
	}
}

.c-feature-modal__link {
	margin-top: $lsu;
}

.c-feature-modal__media {
	width: 100%;
	background: $brand;

	@include bp($people-modal-bp) {
		width: percentage(1 / 3);
		min-width: 300px;
	}

	position: relative;
}

.c-feature-modal__image {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: middle right;
}

.c-feature-modal__cta-list {
	margin-top: $bsu;

	@include flex-extend;
	flex-direction: column;
	align-items: flex-start;
}

.c-feature-modal__cta {
	@include link-brand;

	margin-top: $ssu;

	display: inline-flex;
	align-items: center;
}

.c-feature-modal__cta-text {
	@include font-24b;
}

.c-feature-modal__cta {}

.c-feature-modal__cta-icon {
	flex-shrink: 0;
	margin-right: $ssu;
}
