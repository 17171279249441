/* OBJECT.LAYOUT */

.o-layout-wrapper {
	@include layout-wrapper;
	// Remove padding on sides if a layout-wrapper is nested within another layout-wrapper
	.o-layout-wrapper {
		padding-left: 0;
		padding-right: 0;

		.c-themed-controls & {
			max-width: #{$layout-wrapper-max-width - ($bsu * 2)};
		}
	}

	.c-modal__body & {
		padding: 0;
	}
}

.o-layout-page {
	padding-top: $bsu;

	@include bp("medium") {
		padding-top: $lsu;
	}

	@include bp(1440px) {
		padding-top: $bsu;
	}

	@include bp(1600px) {
		padding-top: 0;
	}
}

.o-layout-page--video-first {
	padding-top: 0;

	@include bp('large') {
		margin-top: -40px;
	}
}

.o-layout-content--has-aside {

	@include bp('large') {
		@include flex-extend;
	}
}

.o-layout-main {
	display: block;

	.o-layout-content--has-aside & {

		@include bp('large') {
			width: calc(100% - #{$layout-aside-width + $layout-aside-gutter-width});
		}
	}
}

.o-layout-aside {
	margin-top: $lsu;

	:first-child {
		margin-top: 0;
	}

	@include bp('large') {
		width: $layout-aside-width + $layout-aside-gutter-width;
		padding-left: $layout-aside-gutter-width;
		margin-top: 0;

		&.is-left {
			padding-left: 0;
			padding-right: $layout-aside-gutter-width;
		}
	}

	&:first-child {
		margin-top: 0;
	}
}

.o-layout-header {
	margin-bottom: $lsu;

	@include bp("medium") {
		margin-bottom: $hsu;
	}

	> *:last-child {
		margin-bottom: 0;
	}
}

.o-layout-header__heading {
	@include font-50h;
	margin-bottom: 30px;

	@include bp("large") {
		margin-bottom: $lsu;
	}
}

.o-layout-header__heading--after-video {
	margin: 30px 0;

	@include bp("large") {
		margin-bottom: $lsu;
	}
}

.o-layout-header__heading--small {
	@include font-32h;
	margin-bottom: $bsu;
}

.o-layout-header__subtitle {
	display: block;

	@include font-24b;
	color: $grey--dark;
	margin-top: -$bsu;
	margin-bottom: $lsu;
}

.o-layout-header__lede {
	@include font-18b;

	a {
		@include link-brand;
	}

	> *:last-child {
		margin-bottom: 0;
	}
}

.o-layout-wrapper {

	.o-layout-header__lede {
		padding: 0;
	}
}

.o-layout-header__date {
	@include font-12;
	margin-bottom: 2em;
}
