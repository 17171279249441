// CONTROL.RESOURCES-GERARD

.c-resources__item--featured {
	&::before {
		display: none;
	}

	&::after {
		background-color: $brand--light;
	}
}
