// COMPONENT.RELATED-ITEMS-GERARD

.c-related-item__link {
	padding: 0;
	display: flex;

	border: 1px solid $brand--alt;

	&:hover,
	&:focus {
		border-color: $brand--alt;
		.c-related-item__text {
			background: $brand--alt;
			color: $white;
		}
	}
}

.c-related-item__icon {
	display: block;

	top: auto;
	left: auto;
	bottom: auto;
	right: auto;
	transform: none;

	width: 46px;
	flex-grow: 0;
	flex-shrink: 0;

	position: relative;
	&::before {
		@include abs-center;
	}

	background: $brand--alt;
	color: $white;
}

.c-related-item__text {
	display: block;
	flex-grow: 1;
	padding: $ssu $bsu;

	@include link-transition;
}
