// COMPONENT.FOOTER-GERARD

.c-footer {
	@include brand-gradient;
}

.c-footer__layout {
	@include bp("medium") {
		&::before {
			background: $white;
		}
	}
}

.c-footer__logo-link {
	margin-top: 0;
}

.c-footer__logo-image {
	width: 215px;
	height: auto;
	@include bp("large") {
		width: 315px;
	}
}

.c-footer__social-list {
	margin-top: -$tsu;
}

.c-footer__social-item {
	padding-top: $tsu;
}

.c-footer__social-link {
	background: $white;
	color: $brand;
	border-radius: 100%;
	font-size: 20px;

	&:hover,
	&:focus {
		background: $brand;
		color: $white;
	}
}
