﻿/* Dropdown Button */
.dropbtn {
	background-color: #494c4d;
	color: white;
	padding: 6px 16px;
	font-size: 14px;
	text-align: left;
	border: none;
	cursor: pointer;
	@include link-transition;

	@include bp("large") {
		font-size: 16px;
		text-align: center;
	}
}

/* Dropdown button on hover & focus */
.dropbtn:hover, .dropbtn:focus {
	background-color: #dee3e3;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
	position: relative;
	display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
	display: none;
	position: absolute;
	background-color: #f1f1f1;
	min-width: 160px;
	box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
	z-index: 1;
}

#myDropdownMobile {
	position: relative;
	box-shadow: none;
}
/* Links inside the dropdown */
.dropdown-content a {
	color: black;
	padding: 12px 16px;
	text-decoration: none;
	display: block;
	font-size: 14px;

	@include bp("large") {
		font-size: 16px;
	}
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
	background-color: #ddd;
}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.show {
	display: block;
}
