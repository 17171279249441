// CONTROL.FIND-A-DISTRIBUTOR

$find-a-distributor-bp: "large";

.c-find-a-distributor {}

.c-find-a-distributor__heading {
	padding-bottom: $bsu;
	margin-bottom: $lsu;

	position: relative;
	&::after {
		content: "";

		position: absolute;
		bottom: 0;
		left: 0;

		width: 100%;
		max-width: 1000px;
		height: 3px;
		background-color: $grey--light;
	}
}

.c-find-a-distributor__layout {
	display: flex;
	@include bp-below($find-a-distributor-bp) {
		flex-direction: column-reverse;
	}
	@include bp($find-a-distributor-bp) {
		flex-direction: row;
	}
}

//////////
// BODY //
//////////
.c-find-a-distributor__body {
	@include bp-below($find-a-distributor-bp) {
		margin-top: $lsu;
	}
	@include bp($find-a-distributor-bp) {
		width: 40%;
	}

	@include bp("small") {
		columns: 2;
		column-gap: $lsu;
	}
}

.c-find-a-distributor__region {
	margin-bottom: $bsu;

	padding-bottom: $bsu;

	position: relative;
	&::after {
		content: "";

		position: absolute;
		bottom: 0;
		left: 0;

		width: 80%;
		height: 1px;
		background-color: $grey--light;
	}

	break-inside: avoid;
}

.c-find-a-distributor__region-name {
	display: block;
	margin-bottom: $bsu;

	@include font-24h;
}

.c-find-a-distributor__location-list {
	@include list-reset;
}

.c-find-a-distributor__location-item {
	margin-top: $bsu;
}

.c-find-a-distributor__location {
	break-inside: avoid;
}

.c-find-a-distributor__location-name {
	@include font-20b;
}

.c-find-a-distributor__distributor {
	margin-top: $ssu;

	+ .c-find-a-distributor__distributor {
		margin-top: $bsu;
	}
}

.c-find-a-distributor__distributor-name,
.c-find-a-distributor__distributor-contact,
.c-find-a-distributor__distributor-phone {
	display: block;

	@include font-16;
	@include bp("large") {
		@include font-18;
	}
}

.c-find-a-distributor__distributor-phone {
	@include link-brand;
}

///////////
// MEDIA //
///////////
.c-find-a-distributor__media {
	@include bp($find-a-distributor-bp) {
		width: 60%;
	}
}

.c-find-a-distributor__image {
	@include bp-below($find-a-distributor-bp) {
		max-width: 750px;
		margin: 0 auto;
	}
}
