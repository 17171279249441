// COMPONENT.SIDE-BY-SIDE

.c-side-by-side {}

.c-side-by-side__sides {
	@include flex-extend;
	flex-wrap: wrap;

	margin-left: -$lsu;
}

.c-side-by-side__side {
	width: 100%;
	padding-left: $lsu;
	padding-bottom: $lsu;

	&:last-child {
		padding-bottom: 0;
	}

	& + & {
		margin-top: 0;
	}

	@include bp("medium") {
		width: 50%;
		padding-bottom: 0;
	}
}

.c-side-by-side__side-title {
	@include font-18b;
	display: block;
	margin-bottom: $bsu;
}

.c-side-by-side__side-text {
	@include font-14;
}
