/* COMPONENT.MEGAMENU-GERARD */

.c-megamenu__item-image-wrapper {
	@include bp("large") {
		&::before {
			transform: rotate(35deg) translate(60px, 0) rotate(-35deg);
		}
	}
}

.c-megamenu__link {
	&:hover,
	&:focus {
		.c-megamenu__item-image-wrapper {
			&::before {
				// transform: translate(0, 0);
			}
			&::after {
				// Using angle 60
				// multiplier (cotan(60) = 0.5774

				border-bottom-width: 40px;
				border-top-width: 40px;
				border-right-width: #{40px * 0.5774};
				border-left-width: #{40px * 0.5774};
			}
		}
	}
}

.c-megamenu__item-name {
	color: $brand;
}

.c-megamenu__link-text {
	color: $brand;
}
