// COMPONENT.NAVIGATION

// Main Navigation
$navigation-bg: $white;

.c-navigation {
	display: none;
	width: 100%;
	background-color: $navigation-bg;

	@include bp-below("large") {
		overflow-y: scroll;
		height: calc(100vh - #{$header-height--mobile});
	}

	@include bp("large") {
		display: block;
		flex-grow: 1;
		align-self: flex-end;
		width: auto;
		background-color: transparent;
	}

	&[aria-expanded="true"] {
		display: block;
		position: absolute;
		top: $header-height--mobile;
		left: 0;
		height: calc(100vh - #{$header-height--mobile});
	}
}

.c-navigation__items {
	padding-top: $tsu;

	@include bp("large") {
		width: 100%;
		@include flex-extend;
		align-items: stretch;
		justify-content: flex-end;
		padding: 0;
	}
}

.c-navigation__item {
	@include bp-below("large") {
		border-top: 1px solid $grey--light;
		&:last-child {
			border-bottom: 1px solid $grey--light;
		}
	}
	&[aria-current="true"] {
		.c-navigation__link {
			color: $brand;
		}
	}
}

.c-navigation__item--has-children {
	@include bp-below("large") {
		.c-navigation__link {
			position: relative;
			padding-right: $lsu;
			&::after {
				position: absolute;
				top: 50%;
				right: $ssu;
				transform: translateY(-50%);

				@include font-icon;
				content: $iconf-arrow-right;
				color: $brand;

				transform-origin: 50% 50%;
				@include link-transition;
			}
		}
	}
	@include bp("large") {
		.c-navigation__link {
			&::after {
				@include font-icon;
				content: $iconf-chevron-right;

				position: relative;
				top: 2px; // Adjust for weird line height

				display: inline-block;
				vertical-align: center;
				font-size: 16px;

				@include link-transition;
			}
		}
		&[aria-expanded="true"] {
			.c-navigation__link {
				&::after {
					transform: rotate(90deg);
				}
			}
		}
	}
}

.c-navigation__link {
	@include font-14h;
	color: $grey--dark;

	display: block;
	padding: $ssu $hsu $ssu $bsu;

	@include bp("large") {
		display: inline-block;
		padding: $bsu $ssu;
		white-space: nowrap;

		.c-navigation__item:hover & {
			text-decoration: underline;
		}
	}
}

.c-navigation__link--home {
	font-size: rem(20px);

	@include bp("large") {
		line-height: 1;
	}
}

// Navigation Drop Down
.c-navigation-dropdown__items {
	display: none;
	padding: $ssu 0 $ssu $bsu;
	width: 100%;
	background: $navigation-bg;

	@include bp("large") {
		position: absolute;
		padding: 0;
		min-width: 250px;
		left: 50%;
		transform: translateX(-50%);
		box-shadow: 0 0 10px 0 rgba($black, 0.2);

		:focus-within > & { // :focus-within is not supported by IE
			display: block;
		}
	}

	[aria-expanded="true"] > &,
	.no-js :hover > & {
		display: block;
	}
}

.c-navigation-dropdown__item {
	display: inline-block;
	width: 100%;
	margin-bottom: $ssu;

	@include bp("large") {
		@include ui-border('bottom');
		margin: 0;
	}
}

.c-navigation-dropdown__link {
	@include font-14b;
	display: block;
	padding: $tsu 0;
	color: $body-color;

	&::before {
		display: none;
	}

	@include bp("large") {
		position: relative;
		padding: $ssu $lsu $ssu $bsu;

		&:hover {
			text-decoration: underline;
		}
	}
}

.c-navigation__skiplink {
	background-color: $brand;
	color: $white;
	padding: 5px 10px;
	margin: 5px;
	position: fixed;
	top: 5px;
	left: 5px;
	z-index: 1000;
	border-radius: 5px !important;
	border: 1px solid $brand;
	opacity: 0;
	transition: all 0.5s ease;

	&:focus {
		opacity: 1;
	}
}

// Language container - append around used to insert language picker in here when it's visible
.c-navigation__language {
	padding: $bsu;

	@include bp('medium') {
		display: none;
	}
}

.c-navigation__regional {
	display: flex;

	@include bp("large"){
		display: none;
	}
}

.c-navigation__region {
	width: 100%;
}

.c-navigation__lang {
	width: 100%;

	> div {
		width: 100%;
		#LanguageDropdownMobile {
			width: 100%;
		}
	}
}