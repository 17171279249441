// COMPONENT.PRODUCTS

.c-products {}

.c-products__list {
	@include list-reset;
	margin-top: -$ssu;
}

.c-products__item {
	@include flex-extend;
	align-items: stretch;

	@include bp-below("medium") {
		flex-wrap: wrap;
	}

	border: 1px solid $grey;

	margin-top: $ssu;
}

.c-products__item-image-container {
	flex-shrink: 0;
	align-self: flex-start;
	width: 100%;
	@include bp("medium") {
		width: 50%;
		max-width: 250px;
	}

	position: relative;

	.c-products__item-image {
		display: block;
		overflow: hidden;
	}
	&:hover,
	&:focus {
		.c-products__item-image img {
			transform: scale(1.1);
		}
	}
}

.c-products__item-image-icon {
	position: absolute;
	bottom: $bsu;
	right: $bsu;

	width: 50px;
	height: 50px;
	object-size: contain;
}

.c-products__item-image img {
	@include link-transition(transform);
}

.c-products__item-body {
	padding: $bsu 30px;
	overflow:hidden;
}

.c-products__item-name {
	display: block;
	margin-bottom: $bsu;

	@include font-24h;
}

.c-products__item-name-link {
	@include link-transition;

	color: $body-color;
	&:hover,
	&:focus {
		color: $brand;
	}
}

.c-products__item-details {
	@include flex-extend;
	align-items: flex-start;
	flex-wrap: wrap;

	margin-left: -$bsu;
}

.c-products__item-abstract {
	display: block;
	padding-left: $bsu;

	@include font-14;

	@include bp("medium") {
		width: calc(100% - 160px);
	}
}

.c-products__item-variant-list {
	@include list-reset;
	@include flex-extend;
	flex-wrap: wrap;

	margin-left: -2px;
	padding-left: $bsu;
	margin-top: -2px;
	margin-bottom: $ssu;

	@include bp("medium") {
		width: 160px;
		flex-shrink: 0;
	}
}

.c-products__item-variant {
	width: 40px;
	@include bp("medium") {
		width: 25%;
	}
	padding-left: 2px;
	padding-top: 2px;
}

.c-products__item-variant-image {
	display: block;
	width: 100%;
}

.c-products__item-actions {
	@include font-16h;
	@include flex-extend;
	flex-wrap: wrap;

	padding-left: $bsu;

	@include bp-below("medium") {
		//margin-left: -$ssu;
	}
	
}

.c-products__item-quote,
.c-products__item-view {
	display: block;

	@include font-16h;
	@include link-transition;

	padding: $ssu;
	margin-bottom: $ssu;
	@include bp("medium") {
		margin-right: $bsu;
		margin-bottom: 0;
		&:first-child {
			//margin-left: -$ssu;
		}
		&:last-child {
			margin-right: 0;
		}
	}
}

.c-products__item-quote {
	color: $grey--dark;
	&:hover,
	&:focus {
		color: $brand;
	}
}

.c-products__item-view {
	color: $brand;
	&:hover,
	&:focus {
		color: $brand--alt;
	}
}
