// CONTROL.PATHWAYS

.c-pathways {}

.c-pathways__list {
	@include list-reset;

	@include flex-extend;
	flex-wrap: wrap;

	margin-top: -1px;
	margin-left: -1px;
}

.c-pathways__item {
	width: 100%;
	@include bp("medium") {
		width: 50%;
	}

	position: relative;

	padding-top: 1px;
	padding-left: 1px;
}

.c-pathways__layout {
	position: relative;
	height: 100%;
}

.c-pathways__image {
	display: block;
	width: 100%;
	overflow: hidden;
}

@mixin pathway-bg-transform($offset) {
	// Scale is enough to cover 100% at this angle
	transform: rotate($brand-angle) scale(1.25, 1.6) translateY($offset);
}

.c-pathways__body {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	padding: $bsu;
	@include bp("medium") {
		padding: $lsu;
	}

	color: $white;
	background: linear-gradient(to bottom, rgba($black, 0.6) 0%, rgba($black, 0) 150px);

	overflow: hidden;
	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;


		transform-origin: 50% 50%;
		@include pathway-bg-transform(-60px);
		@include bp(375px) {
			@include pathway-bg-transform(-90px);
		}
		@include bp("medium") {
			@include pathway-bg-transform(-500px);
			opacity: 0;
		}

		@include link-transition;

		background: rgba($brand, 0.8);
	}
}
@include bp-below("medium") {
	.c-pathways__item:nth-child(2n) {
		.c-pathways__body::before {
			background: rgba($grey--dark, 0.8);
		}
	}
}

.c-pathways__title {
	display: block;
	margin-bottom: $bsu;
	@include bp("medium") {
		max-width: 80%;
		margin-bottom: $lsu;
	}
	position: relative;

	@include font-50h;
	margin-top: -0.3em; // Account for weird line height

	white-space: nowrap;
}
.o-layout-content--has-aside {
	.c-pathways__title {
		@include font-32h;
	}
}

.c-pathways__title-text {
	display: inline-block;
	padding-right: 1.2em;

	white-space: normal;
}

.c-pathways__title-icon {
	display: inline-block;
	margin-left: 0.2em;

	position: relative;
	right: 1.2em;

	&::after {
		@include font-icon;
		content: $iconf-arrow-right;

		display: inline-block;
		font-size: 0.8em;

		@include bp("medium") {
			opacity: 0;
			transform: translateX(-0.5em);
			@include link-transition;
		}
	}
}

.c-pathways__abstract {
	@include font-14;

	max-width: 80%;
	@include bp("medium") {
		max-width: 50%;

		opacity: 0;
		@include link-transition;

		animation: 0.3s becomeHidden forwards;
	}
	@include bp-below("medium") {
		max-height: 70px;
		overflow: hidden;
	}
	position: relative;
}

.c-pathways__body {
	&:hover,
	&:focus {
		&::before {
			@include bp("medium") {
				@include pathway-bg-transform(-80px)
				opacity: 1;
			}
			@include bp("large") {
				@include pathway-bg-transform(-130px)
			}
		}

		.c-pathways__title-icon::after {
			opacity: 1;
			transform: none;
		}

		.c-pathways__abstract {
			animation: 0.3s becomeVisible forwards;
			opacity: 1;
		}
	}
}
