﻿// BASE.THEME-FONTS-DEFAULT


// Config
//-------
// Base settings all set on the HTML element
// $base-font-size is used throughout to calculate em/rem sizes
$base-font-family: "Flama-Base", Arial, Helvetica, sans-serif;
$base-font-size: 16px;
$base-line-height: 22px;

$brand-font-family: "Flama-Heading", Arial, Helvetica, sans-serif;

$weight--light: 300;
$weight--normal: 400;
$weight--semibold: 600;
$weight--bold: 700;

// Font Faces

// Flama
//
// Source: Font files provided directly by client
// Transformed: Generated by Font Squirrel (https://www.fontsquirrel.com) on March 11, 2022
//
// Licence: No licence file provided by client

@font-face {
	font-family: "Flama-Heading";
	src: url("/content/fonts/flama/flama-basic.eot");
	src: url("/content/fonts/flama/flama-basic.eot?#iefix") format("embedded-opentype"),
	     url("/content/fonts/flama/flama-basic.woff2") format("woff2"),
	     url("/content/fonts/flama/flama-basic.woff") format("woff"),
	     url("/content/fonts/flama/flama-basic.ttf") format("truetype"),
	     url("/content/fonts/flama/flama-basic.svg#flama-basic") format("svg");
	font-weight: $weight--normal;
	font-style: normal;
	font-display: auto;
}

@font-face {
	font-family: "Flama-Heading";
	src: url("/content/fonts/flama/flama-medium.eot");
	src: url("/content/fonts/flama/flama-medium.eot?#iefix") format("embedded-opentype"),
	     url("/content/fonts/flama/flama-medium.woff2") format("woff2"),
	     url("/content/fonts/flama/flama-medium.woff") format("woff"),
	     url("/content/fonts/flama/flama-medium.ttf") format("truetype"),
	     url("/content/fonts/flama/flama-medium.svg#flama-medium") format("svg");
	font-weight: $weight--semibold;
	font-style: normal;
	font-display: auto;
}

@font-face {
	font-family: "Flama-Heading";
	src: url("/content/fonts/flama/flama-bold.eot");
	src: url("/content/fonts/flama/flama-bold.eot?#iefix") format("embedded-opentype"),
	     url("/content/fonts/flama/flama-bold.woff2") format("woff2"),
	     url("/content/fonts/flama/flama-bold.woff") format("woff"),
	     url("/content/fonts/flama/flama-bold.ttf") format("truetype"),
	     url("/content/fonts/flama/flama-bold.svg#flama-bold") format("svg");
	font-weight: $weight--bold;
	font-style: normal;
	font-display: auto;
}

@font-face {
	font-family: "Flama-Base";
	src: url("/content/fonts/flama/flama-light.eot");
	src: url("/content/fonts/flama/flama-light.eot?#iefix") format("embedded-opentype"),
	     url("/content/fonts/flama/flama-light.woff2") format("woff2"),
	     url("/content/fonts/flama/flama-light.woff") format("woff"),
	     url("/content/fonts/flama/flama-light.ttf") format("truetype"),
	     url("/content/fonts/flama/flama-light.svg#flama-light") format("svg");
	font-weight: $weight--normal;
	font-style: normal;
	font-display: auto;
}
