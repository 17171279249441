/* COMPONENT.MEGAMENU */

$megamemu__intro-width: 400px;

.c-megamenu {
	background: $white;

	@include bp-below("large") {
		visibility: hidden;

		&[aria-expanded="true"],
		[aria-expanded="true"] > & {
			animation: 0.3s slideinRight forwards,
			           0.3s becomeVisible forwards;
		}
		&[aria-expanded="false"],
		[aria-expanded="false"] > & {
			animation: 0.3s slideoutRight forwards,
			           0.3s becomeHidden forwards;
		}
	}

	@include bp("large") {
		display: none;
		&[aria-expanded="true"],
		[aria-expanded="true"] > & {
			display: block;
		}
		&[aria-expanded="true"] {
			box-shadow: 0 8px 6px -6px rgba($black, 0.2);
		}


		transition: max-height 0.5s ease-in-out;
		position: absolute;
		left: 0;
		display: block;
		width: 100%;
		overflow: hidden;
		text-align: left;
		box-shadow: 0 8px 6px -6px rgba($black, 0.2);

		display: none;
		&[aria-expanded="true"],
		[aria-expanded="true"] > & {
			display: block;
		}

		.no-js .c-navigation__item:hover & {
			display: block;
		}
	}

	@include bp-below("large") {
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		right: 0;
		min-height: 100%;
	}
}

.c-megamenu__wrapper {
	@include clearfix;

	@include bp("large") {
		max-width: em($layout-wrapper-max-width);
		padding: 0 $bsu;
		padding-top: $bsu * 3;
		padding-bottom: $bsu * 3;
		margin: 0 auto;
	}
}

.c-megamenu__section-title {
	width: 100%;

	background: $grey--lightest;
	@include font-20h;
	color: $brand;

	@include flex-extend;
	align-items: stretch;
	border-bottom: 1px solid $grey--light;

	@include bp("large") {
		display: none;
	}
}

.c-megamenu__section-title-link {
	display: block;
	padding: $bsu $bsu $bsu 0;

	color: $brand;
}

.c-megamenu__section-title-back {
	@include button-reset;
	min-width: 44px;
	min-height: 44px;
	padding: 0 $bsu;

	@include flex-extend;
	align-items: center;
	justify-content: flex-end;

	color: $brand;

	&::before {
		@include font-icon;
		content: $iconf-arrow-left;
		font-size: 0.8em;
	}
}

.c-megamenu__items {
	@include flex-extend;
	flex-direction: column;

	@include bp("large") {
		padding: 0;
		flex-direction: row;
		flex-wrap: wrap;

		margin-left: -$bsu;
		margin-top: -$bsu;
	}
	@include bp("extra-large") {
		margin-left: -$lsu;
		margin-top: -$lsu;
	}
}

.c-megamenu__heading {
	@include bp("large") {
		display: none;
	}
}

.c-megamenu__close {
	@include button-reset;

	@include flex-extend;
	align-items: center;
	height: 80px;
	width: 100%;
	padding: $bsu;

	@include font-20h;
	color: $brand;
	background: $grey--lightest;

	&::before {
		@include font-icon;
		content: $iconf-arrow-left;

		margin-right: $bsu;
	}
}

.c-megamenu__item {
	display: inline-block;
	width: 100%;

	@include bp("large") {
		width: percentage(1/6);
		margin: 0;
		padding-left: $bsu;
		padding-top: $bsu;
	}
	@include bp("extra-large") {
		padding-left: $lsu;
		padding-top: $lsu;
	}

	@include flex-extend;
	align-items: stretch;
}

.c-megamenu__item--all {
	@include bp-below("large") {
		.c-megamenu__link {
			display: block;
			padding: $bsu;

			@include font-16h;
			color: $grey--dark;
		}
	}

	@include bp("large") {
		.c-megamenu__link {
			width: 100%;
			height: 100%;

			@include flex-extend;
			align-items: center;
			justify-content: center;

			@include link-plain;
			@include font-14b;
			&:hover,
			&:focus {
				.c-megamenu__link-text {
					text-decoration: underline;
				}
			}

			&::after {
				@include font-icon;
				content: $iconf-arrow-right;

				display: inline-block;
				padding-left: $ssu;
			}
		}
	}
}

.c-megamenu__link {
	display: block;
	width: 100%;

	@include bp-below("large") {
		@include flex-extend;
		align-items: center;
	}
}

.c-megamenu__item-image-wrapper {
	display: block;

	@include bp-below("large") {
		width: 80px;
		flex-shrink: 0;
	}

	@include bp("large") {
		margin-bottom: $tsu;
		position: relative;
		overflow: hidden;

		&::before,
		&::after {
			@include link-transition;
		}
		&::before {
			@include font-icon;
			content: $iconf-arrow-right;

			position: absolute;
			z-index: 1;
			right: 0;
			bottom: 0;
			color: $brand;
			font-size: 24px;

			transform: rotate(35deg) translate(60px, 0) rotate(-35deg);
		}
		&::after {
			content: "";
			position: absolute;
			bottom: 0;
			right: 0;

			display: block;
			width: 0;
			height: 0;

			border-bottom: 0 solid $white;
			border-top: 0 solid transparent;
			border-right: 0 solid $white;
			border-left: 0 solid transparent;
		}
	}
}

.c-megamenu__item-image {
	display: block;
	width: 100%;
	@include bp-below("large") {
		height: 100%;
	}
}

.c-megamenu__item-body {
	@include bp-below("large") {
		flex-grow: 1;
		max-width: calc(100% - 80px);
		padding-left: $bsu;
		border-bottom: 1px solid $grey--light;

		@include flex-extend;
		flex-direction: column;
		justify-content: center;
	}
}

.c-megamenu__item-name {
	display: block;
	margin-top: -2px;

	@include font-14h;
	color: $grey--dark;

	@include bp("large") {
		color: $black;
		margin-top: 0;
	}
}

.c-megamenu__item-subtitle {
	display: block;
	margin-top: 2px;
	margin-bottom: 5px;

	@include font-10;
	color: $grey--dark;

	@include bp("large") {
		margin-top: $tsu;
		margin-bottom: 0;
	}

	@include bp-below("large") {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: calc(100% - #{$bsu});
	}
}

.c-megamenu__link {
	@include bp-below("large") {
		@include flex-extend;
		align-items: stretch;
		min-height: 45px;
	}

	&:hover,
	&:focus {
		.c-megamenu__item-image-wrapper {
			&::before {
				opacity: 1;

				transform: translate(0, 0);
			}
			&::after {
				border-bottom-width: 27px;
				border-top-width: 27px;
				border-right-width: #{27px * $brand-angle-multiplier};
				border-left-width: #{27px * $brand-angle-multiplier};
			}
		}
	}
}
