﻿// CONTROL.THEMED-CONTROLS-GERARD

.c-themed-controls--yellow {
	background-color: $yellow--pale;
}

.c-themed-controls--blue {
	background-color: $blue--pale;
}

.c-themed-controls--green {
	background-color: $green--pale;
}
