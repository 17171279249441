﻿// CONTROL.IMAGE-GALLERY

.c-image-gallery {
	.flickity-prev-next-button {
		.flickity-button-icon {
			display: none;
		}

		&::before {
			@include font-icon;

			font-size: 25px;
		}
		&.previous {
			&::before {
				content: $iconf-arrow-left;
			}
		}
		&.next {
			&::before {
				content: $iconf-arrow-right;
			}
		}
	}
}

.c-image-gallery__slides {
	@include list-reset;
}

.c-image-gallery__slide {
	width: 100%;

	&:nth-child(n+2) {
		display: none;

		.flickity-enabled & {
			display: block;
		}
	}
}

.c-image-gallery__caption {
	@include flex-extend;
	justify-content: flex-end;
}

.c-image-gallery__caption-text {
	flex: 1 0 auto;
}

.c-image-gallery__caption-count {
	display: none;

	@include bp('medium') {
		display: inline;
		padding-left: $lsu;
		white-space: nowrap;
	}
}
