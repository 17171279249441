﻿// CONTROL.IMAGE-FEATURE

.c-image-feature {
	position: relative;
	overflow: hidden;
	max-width: 1920px;
	margin: 0 auto;

	@include bp("medium") {
		@include flex-extend;
		align-items: center;
		padding: 0;
	}
}

.c-image-feature__figure {
	//position: relative;
	flex: 0 0 50%;
	overflow: hidden;
	padding: 15px;

	@include bp('small') {
		padding: 30px;
	}

	@include bp('large') {
		padding: 60px;
	}
}

.c-image-feature__img-wrap {
	position: relative;
	z-index: 2;
}

.c-image-feature__body {
	position: relative;
	width: 100%;
	padding: $bsu;
	padding-bottom: 14%;
	z-index: 3;

	@include bp("medium") {
		position: static;
		padding: $lsu;
		padding-bottom: 7%;
	}

	@include bp("large") {
		padding: $hsu;
		padding-bottom: 7%;
	}

	> *:last-child,
	&:last-child {
		margin-bottom: 0;
	}
}

.c-image-feature__heading {
	@include font-14hb;
	margin-bottom: $bsu;
	color: $brand;

	@include bp('small') {
		@include font-20h;
	}

	@include bp('medium') {
		@include font-32h;
	}

	@include bp('large') {
		@include font-50h;
	}
}

.c-image-feature__text {
	margin-bottom: $bsu;

	p {
		@include font-14;

		@include bp('medium') {
			@include font-16;
		}

		@include bp('large') {
			@include font-18;
		}
	}

	*:last-child {
		margin-bottom: 0;
	}
}

[class*="c-image-feature__tri"] {
	position: absolute;
	display: block;

	&::before {
		content: "";
		position: absolute;
		z-index: 1;
		width: 100%;
		height: 0;
		padding-bottom: percentage(1 / $brand-angle-multiplier);
	}
}


.c-image-feature__tri-1 {
	left: 0;
	top: 0;
	width: 80%;

	@include bp('medium') {
		width: 60%;
	}

	@include bp('large') {
		width: 40%;
	}

	&::before {
		top: 0;
		left: 0;
		background-color: $grey--lighter;
		clip-path: polygon(0 0, 100% 0, 0 100%);
	}
}

.c-image-feature__tri-3 {
	right: 0;
	bottom: 0;
	width: 20%;

	@include bp('medium') {
		width: 10%;
	}

	&::before {
		bottom: 0;
		right: 0;
		background-color: $grey--lightest;
		clip-path: polygon(100% 0, 100% 100%, 0 100%);
	}
}

// Right image variations
.c-image-feature--right {

	.c-image-feature__figure {
		position: relative;
	}

	.c-image-feature__tri-1 {
		width: 100%;

		&::before {
			background-color: $grey--lightest;
		}
	}

	@include bp("medium") {
		flex-flow: row-reverse;

		.c-image-feature__figure {
			position: static;
		}

		.c-image-feature__body {
			padding-bottom: $lsu;
		}

		.c-image-feature__tri-1 {
			width: 70%;
		}

		.c-image-feature__tri-2 {
			width: 30%;
		}

		.c-image-feature__tri-3 {
			display: none;
		}
	}

	@include bp("large") {

		.c-image-feature__body {
			padding-bottom: $hsu;
		}
	}
}

// Only output on right image option
.c-image-feature__tri-2 {
	right: 0;
	bottom: 0;
	width: 75%;

	&::before {
		bottom: 0;
		right: 0;
		background-color: $brand;
		clip-path: polygon(100% 0, 100% 100%, 0 100%);
	}
}