// COMPONENT.TABS

.c-tabs {
	background: $grey--lightest;
	padding: $ssu 0;
	@include bp("medium") {
		padding: 0;
	}

	margin-bottom: $lsu;
}

.c-tabs__list {
	@include list-reset;
	flex-wrap: wrap;
	@include flex-extend;
	align-items: stretch;

	@include bp-below("medium") {
		justify-content: center;
	}
}

.c-tabs__item {
	@include flex-extend;
	align-items: stretch;
}

.c-tabs__link {
	display: block;

	@include font-14h;

	@include link-transition;
	color: $grey--dark;
	&:hover,
	&:focus,
	&[aria-current="page"] {
		color: $brand;
	}

	padding: 7px $ssu;
	@include bp("medium") {
		padding: 20px 30px;
	}
	@include bp("large") {
		padding: 30px 55px;
	}

	position: relative;

	&[aria-current="page"] {
		&::after {
			content: "";
			position: absolute;
			bottom: 0;

			left: $bsu;
			right: $bsu;

			border-bottom: 2px solid $brand;

			@include link-transition(border-bottom);
		}
	}
}
