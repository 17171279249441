// CONTROL.FIND-A-DISTRIBUTOR-GERARD

.c-find-a-distributor {
	margin: 0;
	padding: $lsu 0;
	@include bp("large") {
		padding: $hsu 0;
	}
	background: $brand;
	color: $white;
}

.c-find-a-distributor__heading {
	&::after {
		background-color: $brand--light;
	}
}

.c-find-a-distributor__region {
	&::after {
		background-color: $brand--light;
	}
}

.c-find-a-distributor__region-name {
	color: $brand--light;
}

.c-find-a-distributor__distributor-phone {
	&,
	&:hover,
	&:focus {
		color: inherit;
	}
	text-decoration: underline;
}
