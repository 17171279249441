// COMPONENT.INSPIRATION

.c-inspiration {
}



.c-inspiration-wrapper {
	padding: 40px 0;

	@include bp("large") {
		padding: 120px 0;
	}

	position: relative;
	z-index: 1; // Create stacking context
}

.c-inspiration__header {
	@include bp("medium") {
		display: none;
	}
}

.c-inspiration__background {
	display: none;

	@include bp("large") {
		display: block;
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-size: cover;
		background-position: 50% 50%;
		filter: saturate(0);

		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: rgba($white, 0.85);
		}
	}
}

// Homepage layout

.c-inspiration__homepage {
	position: relative;
	z-index: 2;

	@include list-reset;

	@include flex-extend;
	flex-wrap: wrap;
	margin: 0 0 0 -1px;

	@include bp("large") {
		flex-direction: row;
		align-items: stretch;
		flex-wrap: nowrap;
	}
}

// Product detail layout
.c-inspiration__items {
	position: relative;
	z-index: 2;

	@include list-reset;

	@include flex-extend;
	flex-direction: row;
	flex-wrap: wrap;
	margin: -1px 0 0 -1px;
	align-items: stretch;
}

//////////
// ITEM //
//////////

.c-inspiration__item {
	position: relative;
	overflow: hidden;

	.c-inspiration__homepage & {
		@include bp("medium") {
			width: percentage(1/2);
		}

		@include bp("large") {
			width: percentage(1/4);
			border-right: 1px solid $white;
		}
	}

	.c-inspiration__items & {
		width: 100%;
		border-top: 1px solid $white;

		@include bp("medium") {
			width: percentage(1/2);
			border-left: 1px solid $white;
		}

		@include bp("large") {
			width: percentage(1/3);
		}
	}

	.c-inspiration__mobile-items & {
		width: 100%;
	}
}

.c-inspiration__item {
	&-image {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
		transform-origin: 50% 50%;
		@include link-transition;
	}

	&-body {
		@include button-reset;
		text-align: left;
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		padding: $bsu;
		color: $white;
		background: linear-gradient(to bottom, rgba($black, 0.6) 0%, rgba($black, 0) 200px);
	}

	&-body-details {
		position: absolute;
		top: 25px;
		left: 25px;
		padding-right: 25px;
	}

	&-title {
		display: block;
		@include font-20h margin-bottom: $ssu;
	}

	&-location {
		display: block;
		@include font-12b;
	}

	&-button {
		display: block;
		position: absolute;
		right: 0;
		bottom: $ssu;
		width: 150px;
		background: $white;
		padding: $ssu;

		@include link-transition;
	}

	&-button-primary {
		display: block;
		@include font-14h;
		color: $brand;
		position: relative;
		padding-right: 30px;

		&::after {
			@include font-icon;
			content: $iconf-arrow-right;
			position: absolute;
			right: 0;
			top: 5px;
		}
	}

	&-button-secondary {
		display: block;
		@include font-10ub;
		color: $black;
	}

	&-body {
		&:hover,
		&:focus {
			+ .c-inspiration__item-image {
				transform: scale(1.1);
			}

			.c-inspiration__item-button {
				transform: translateY(-$ssu);
			}
		}
	}
}



//////////
// MAIN //
//////////

.c-inspiration__main {
	@include flex-extend;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	padding: $bsu;
	width: 100%;

	@include bp-below("small") {
		order: 5;
	}

	@include bp("small") {
		width: percentage(1/2);
	}

	@include bp("large") {
		width: percentage(1/4);
	}
}

.c-inspiration__main-title {
	display: block;
	@include font-40b;
	color: $brand;
	margin-bottom: $bsu;

	@include bp("large") {
		margin-bottom: $lsu;
	}
}

.c-inspiration__main-abstract {
	display: block;
	@include font-14b;
	margin: 0 0 $lsu;
}

.c-inspiration__main-title,
.c-inspiration__main-abstract {
	max-width: 300px;
}


////////////////////
// MASONRY LAYOUT //
////////////////////
.c-inspiration__masonry {
	@include list-reset;
	@include flex-extend;
	flex-wrap: wrap;
	overflow: hidden;
	margin-top: 30px;

	@include bp("large") {
		margin-top: $lsu;
	}
}

.c-inspiration__item {
	margin-bottom: 1px;
	visibility: visible;
	width: 100%;

	&--single {
		max-width: $layout-wrapper-max-width - $bsu*2;
		margin: 0 auto !important;
		width: 100% !important;
	}

	&:hover .o-fit {
		transform: scale(1.1);
	}

	.o-fit {
		transition: all 0.25s ease-in-out;
	}

	&:nth-of-type(odd) {
		border-right: 1px $white solid;
	}

	.c-inspiration__details-page & {
		@include bp-below("small") {
			width: percentage(1/2);
		}
	}

	@include bp("small") {
		width: percentage(1/2);
	}

	@include bp("medium") {
		border-right: 1px $white solid;
		width: percentage(1/3);
	}

	@include bp("large") {
		width: percentage(1/4);
	}
}


.c-inspiration__details-page {
	.o-layout-header__content {
		margin-bottom: $ssu;
	}

	.o-layout-header__heading {
		margin-bottom: $ssu;
	}

	.o-layout-header__heading {
		margin-bottom: $ssu;
	}
}


////////////////////////////////
// RELATED INSPIRATION PANELS //
////////////////////////////////
.c-inpiration-related-heading {
	@include font-24h;
	margin-bottom: $ssu;
}

.c-inpiration-related {
	margin: 0;
	padding: 0;
	margin-bottom: $lsu;

	@include bp(735px) {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	@include bp("large") {
		margin-bottom: $hsu;
	}

	.c-inspiration__item-image {
		position: static;
	}

	&__item.c-inspiration__item {
		margin-bottom: 1px;
		width: 100%;

		@include bp(735px) {
			width: calc(#{percentage(1/2)} - 1px);
		}

		@include bp(999px) {
			margin-bottom: 0;
			width: calc(#{percentage(1/3)} - 1.5px);
		}
	}
}
