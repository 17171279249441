﻿.c-hubspot-form {
	.hs-form {
		 max-width: 750px;
	}

	label:not(.hs-form-radio-display):not(.hs-form-checkbox-display):not(.hs-form-booleancheckbox-display):not(.hs-error-msg) {
		display: block;
		padding-bottom: 8px;
		@include font-14;
	}

	.hs-form-radio-display,
	.hs-form-checkbox-display,
	.hs-form-booleancheckbox-display {
		@include font-14;
		display: flex;
		align-items: center;


		span {
			display: inline-block;
		}
	}

	.hs-error-msg {
		@include font-14;
		display: inline-block;
		padding-top: 4px;
		color: $brand;
	}

	.inputs-list {
		@include list-reset;
		margin-bottom: 20px;
	}

	.field {
		margin-bottom: 15px;

	}

	input[type="text"],
	input[type="email"],
	input[type="tel"],
	input[type="number"],
	textarea,
	select {
		width: 100%;
		border: 1px solid #CCCCCC;
	}

	input[type="checkbox"],
	input[type="radio"] {
		& + span {
			width: calc(100% - 20px);
			padding-left: 10px;
		}
	}

	   .hs-input{
		 padding: 8px 12px;
	   }

	.hs-button {

		@include button-reset;
		background: $brand;
		box-shadow: inset 0 0 0 1px $brand;
		color: $white;
		font-size: 14px;
		font-weight: 400;
		line-height: 1.5;

		transition: all 0.25s ease-in-out;
		display: inline-block;
		 padding: 10px 15px;
		border: 0;
		text-align: center;
		text-decoration: none;
		vertical-align: middle;
		cursor: pointer;

		&:hover,
		&:focus {
			background: $white;
			box-shadow: inset 0 0 0 1px $brand;
			color: $brand;
		}
	}
}

.c-hubspot-form__loader {
	@include loader;

	position: relative;
	min-height: 150px;
}

.o-layout-content--has-aside {
	.c-hubspot-form {
		.hs-form {
			 max-width: 100%;
		}
	}
}
