﻿// CONTROL.VIDEO

.c-video__embed {
	padding-bottom: percentage(9/16);

	&.is-playing {
		.c-video__icon {
			display: none;
		}
	}
}

.c-video__cover {
	width: 100%;
	height: 100%;
}

.c-video__icon {
	@include abs-center;
	background: $brand;
	box-shadow: 0 0 5px 5px rgba($black, 0.15);
	color: $white;

	@include circle(75px);
	font-size: 45px;

	@include bp("medium") {
		@include circle(100px);
		font-size: 60px;
	}

	pointer-events: none;

	&[aria-hidden="true"] {
		.c-video-group__overlay & {
			display: none;
		}
	}
}
