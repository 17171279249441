// OBJECT.CTA

.o-cta__body {
	padding: $bsu;
	border: 1px solid $grey;
	margin: $bsu 0;

	> *:last-child {
		margin-bottom: 0;
	}
}

.o-cta__heading {
	@include font-24;
	margin-bottom: 0.7em;
	color: $body-color;
}

.o-cta__text {
	@include font-14;
	color: $body-color;
}