// COMPONENT.FOOTER

.c-footer {
	background: $grey--darkest;
	color: $white;

	overflow: hidden;
}

.c-footer__layout {
	@include layout-wrapper;

	@include flex-extend;

	@include bp("medium") {
		position: relative;
		&::before {
			content: "";
			background: $brand;

			height: 100vw;
			width: 100vw;

			position: absolute;
			top: 0;
			left: 350px;
			transform-origin: 0 100%;

			// Transform so that it's at the correct angle, and
			// the right-most corner is positioned at
			// the absolute pos coordinates specified above
			transform: translateY(-100%) rotate($brand-angle) translateX(-100%);
		}
	}
}


.c-footer__logo {
	display: none;
	@include bp("medium") {
		display: block;
	}

	width: 500px;

	position: relative;
}

.c-footer__logo-link {
	display: inline-block;
	margin-top: $lsu;
}

.c-footer__logo-image {
	display: block;
	width: 180px;
	height: 35px;
}


.c-footer__body {
	flex-grow: 1;
	position: relative; // Create stacking context
}



.c-footer__primary {
	margin: $lsu 0;

	@include flex-extend;
	@include bp-below("medium") {
		flex-wrap: wrap;
		justify-content: center;
	}
}

.c-footer__primary-text {
	display: block;
	width: 100%;

	@include font-24h;

	@include bp-below("medium") {
		text-align: center;
	}
	@include bp-below("large") {
		margin-top: -7px;
	}
	@include bp("medium") {
		width: percentage(2/3);
		padding-right: $lsu;
	}
}

.c-footer__primary-button-wrapper {
	@include bp-below("medium") {
		margin-top: $bsu;
	}
	@include bp("medium") {
		width: percentage(1/3);
	}
}

.c-footer__primary-button {
	text-align: center;

	@include font-16b;
	padding: $bsu;
	@include bp("medium") {
		width: 230px;
		max-width: 100%;
	}
}



.c-footer__secondary {
	margin: $lsu 0;

	@include flex-extend;
}

.c-footer__navigation {
	display: none;
	@include bp("medium") {
		@include flex-extend;
	}

	width: percentage(2/3);

	padding-right: $lsu;
}

.c-footer__navigation-column {
	width: percentage(0.5);
}

.c-footer__secondary-title {
	display: inline-block;
	margin-bottom: $ssu;

	@include font-14h;
	color: $white;
}
a.c-footer__secondary-title {
	&:hover,
	&:focus {
		text-decoration: underline;
	}
}

.c-footer__navigation-list {
	@include list-reset;
	margin-bottom: $bsu;
}

.c-footer__navigation-item {}

.c-footer__navigation-link {
	@include font-14;
	@include link-plain;
	&:hover,
	&:focus {
		text-decoration: underline;
	}
}


.c-footer__social {
	@include bp-below("medium") {
		text-align: center;
		width: 100%;
	}
	@include bp("medium") {
		width: percentage(1/3);
	}
}

.c-footer__social-list {
	@include list-reset;
	@include flex-extend;
	flex-wrap: wrap;

	margin-bottom: $bsu;

	@include bp-below("medium") {
		justify-content: center;
	}
}

.c-footer__social-item {}

.c-footer__social-link {
	display: block;
	margin-right: $tsu;
	width: 44px;
	height: 44px;

	font-size: 25px;
	@include flex-extend;
	align-items: center;
	justify-content: center;

	color: $grey;
	&:hover,
	&:focus {
		color: $white;
	}
	@include link-transition;
}


.c-footer__legal {
	display: block;
	margin-bottom: $tsu;

	@include font-10;
	> a {
		@include link-plain;
		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}
}




.c-footer-back-to-top {
	display: block;
	position: fixed;
	bottom: 30px;
	right: 0;
	padding: $ssu 0;
	z-index: $z-back-to-top;
	width: 45px;
	height: 45px;
	color: $white;
	background: $brand;
	opacity: 0;
	visibility: hidden;
	transition: opacity 0.5s linear,visibility 0s linear 0.5s;

	&:before {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
	}

	&.fade-in {
		opacity: 1;
		visibility: visible;
		transition-delay: 0s;
	}

	@include bp('large') {
		position: relative;
		display: none;
		bottom: 0;
		vertical-align: bottom;
		background: transparent;
		color: $grey--darkest;
		border: 1px solid $grey--darkest;
		border-bottom: 0;
		height: 30px;
		opacity: 1;
		visibility: visible;

		&.sticky-on-desktop {
			display: inline-block;
			opacity: 0;
			visibility: hidden;

			&.fade-in {
				position: fixed;
				bottom: auto;
				top: 50%;
				transform: translateY(-50%);
				width: 45px;
				height: 45px;
				color: $white;
				border: none;
				background: $brand;
				opacity: 1;
				visibility: visible;
			}
		}
	}
}
